import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ImageService, PlatformHelperService, PropertyTypeHelperService, I18nService } from '@app/core';
import { Router } from '@angular/router';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { AppCarouselRestoreService } from '../carousel';
import { IonSlides, ToastController } from '@ionic/angular';
import { faStar, faAward, faBuilding, faWarehouse, faShareAltSquare, faHeart, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { EventCheckerService } from '@app/core/listings';
import { CommonGenericService } from '@app/core/common.service';
import { GenericMapService } from '@app/core/generic-map.service';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { environment } from '@env/environment';
import { ListingDetailService } from '@app/core/listings';
import { LoginFormDialogService } from '../../../shared/login';
import { AuthenticationService } from '../../../core/auth/auth.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { ListingOfferType } from '../../../models/transaction-type.model';
import { AppListingSliderService } from '../listing-slider/app-listing-slider.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
var ListingCardComponent = /** @class */ (function () {
    function ListingCardComponent(loginFormDialog, imageService, router, detailPositionService, carouselRestoreService, platformConfigHelper, dialog, commonService, commomMapSvc, breakpointObserver, propertyHelperService, i18nService, ngNavigatorShareService, toastCtrl, listingService, auth, _eventChecker, _ts, userActivityService, coworkingService, listingSliderService) {
        var _this = this;
        this.loginFormDialog = loginFormDialog;
        this.imageService = imageService;
        this.router = router;
        this.detailPositionService = detailPositionService;
        this.carouselRestoreService = carouselRestoreService;
        this.platformConfigHelper = platformConfigHelper;
        this.dialog = dialog;
        this.commonService = commonService;
        this.commomMapSvc = commomMapSvc;
        this.breakpointObserver = breakpointObserver;
        this.propertyHelperService = propertyHelperService;
        this.i18nService = i18nService;
        this.ngNavigatorShareService = ngNavigatorShareService;
        this.toastCtrl = toastCtrl;
        this.listingService = listingService;
        this.auth = auth;
        this._eventChecker = _eventChecker;
        this._ts = _ts;
        this.userActivityService = userActivityService;
        this.coworkingService = coworkingService;
        this.listingSliderService = listingSliderService;
        this.isMobile = false;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.currentPhotoIndex = 0;
        this.prevCurrentPhotoIndex = 0;
        this.counter = 0;
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: true,
            zoom: 15
        };
        this.photosSet = false;
        this.count = 0;
        this.faShareAltSquare = faShareAltSquare;
        this.screenWidth = window.innerWidth;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
            if (!_this.isMobile) {
                var mqStandAlone = '(display-mode: standalone)';
                if (window.matchMedia(mqStandAlone).matches) {
                    _this.isMobile = window.matchMedia(mqStandAlone).matches;
                }
            }
        });
    }
    ListingCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        var isOffice = this.commonService.isPropertyType(2001, this.listing);
        if (this.listing.listingPhotos) {
            this.removeMap();
        }
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) { return (_this.isLoggedIn = val); });
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe(function (res) {
            _this.customLoginText = res;
        });
        this.hasPointer = window.matchMedia('(pointer: fine)').matches;
        this.faIcon = this.listing && this.listing.listingType.toLowerCase() === 'featured' ? faStar : faAward;
        this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
        this.markers = [this.commonService.createMapMarker(this.listing)];
        this.staticMapImageUrl = this.commomMapSvc.generateStatiMapRequest(this.listing);
        this.listing.listingPhotos.push(this.staticMapImageUrl);
        this.photosSet = true;
        this.faHeart = faHeart;
        this.faExternalLinkAlt = faExternalLinkAlt;
        this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(this.listing.buildingTypeId);
        if (this.listing.propertySubTypes && this.listing.propertySubTypes.length) {
            var propertySubTypes = this.commonService.translateLocaleText(this.listing.propertySubTypes, 'name');
            this.listingSubtypes = this.commonService.transformArrayStringList(propertySubTypes, 'name');
        }
        this.setSharedPrivatePositionsText();
    };
    ListingCardComponent.prototype.ngOnDestroy = function () {
        this.loginSubscription.unsubscribe();
        this.translateSubscription.unsubscribe();
    };
    ListingCardComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    ListingCardComponent.prototype.removeMap = function () {
        var _this = this;
        Object.keys(this.listing.listingPhotos).forEach(function (key) {
            if (_this.listing.listingPhotos[key] && !_this.listing.listingPhotos[key].image) {
                _this.listing.listingPhotos.splice(key, 1);
            }
        });
    };
    ListingCardComponent.prototype.isEndOfSlide = function () {
        return this.currentPhotoIndex === this.listing.listingPhotos.length - 1;
    };
    ListingCardComponent.prototype.getCroppedOrOriginalImage = function (listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    };
    ListingCardComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    ListingCardComponent.prototype.getCompanyLogoImg = function (id) {
        return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
    };
    ListingCardComponent.prototype.slideToIndex = function (event, index) {
        event.stopPropagation();
        this.photoSlider.slideTo(index);
    };
    ListingCardComponent.prototype.openEditListingMenu = function (event, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        event.stopPropagation();
                        return [4 /*yield*/, this.listingService.getRevisionOrActiveVersionBasedOnUserRole(data)];
                    case 1:
                        data = _a.sent();
                        this.dialogRef = this.dialog.open(MyListingEditComponent, {
                            height: 'auto',
                            width: '550px',
                            data: data ? { data: data, myListingPage: this.myListingPage, date: new Date() } : null
                        });
                        this.dialogRef.afterClosed().subscribe(function (result) { });
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingCardComponent.prototype.isFavoriteListing = function () {
        return this.listing ? this.listing.favorite : false;
    };
    ListingCardComponent.prototype.setFavoriteLisitng = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                event.stopPropagation();
                if (!this.isLoggedIn) {
                    this.openLoginDialog()
                        .afterClosed()
                        .subscribe(function () {
                        if (_this.isLoggedIn) {
                            _this.toggleFavorite();
                        }
                    });
                }
                else {
                    this.toggleFavorite();
                }
                return [2 /*return*/];
            });
        });
    };
    ListingCardComponent.prototype.toggleFavorite = function () {
        var _this = this;
        this.listingService.toggleFavoriteListing(this.listing, this.listing.favorite || false).subscribe(function () {
            _this._eventChecker.updateListing$.next(tslib_1.__assign({}, _this.listing, { favorite: !_this.listing.favorite }));
        });
    };
    ListingCardComponent.prototype.openLoginDialog = function () {
        var currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    };
    ListingCardComponent.prototype.openCard2 = function () {
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.listing,
            panelClass: 'custom-modalbox'
        });
        this.dialogRef.afterClosed().subscribe(function (result) { });
    };
    ListingCardComponent.prototype.openCard = function (id, $event, externalLink) {
        if (externalLink === void 0) { externalLink = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = {
                            localStorageKey: this.localStorageKey,
                            searchCriteria: this.searchCriteria,
                            searchTags: this.searchTags,
                            checkBoxValues: this.checkBoxValues,
                            myListingAnalytics: this.myListingAnalytics,
                            myListingPage: this.myListingPage,
                            openUrl: true
                        };
                        $event.stopImmediatePropagation();
                        lang = this.i18nService.getCurrentLanguage();
                        if (window.location.href.includes('/es')) {
                            lang = 'es';
                        }
                        else if (window.location.href.includes('/en')) {
                            lang = 'en';
                        }
                        return [4 /*yield*/, this.listingService.checkListingDetailRoute(id, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                            if (externalLink) {
                                window.open(detailsURL, '_blank');
                                return [2 /*return*/];
                            }
                            if (this.isMobile) {
                                this.router.navigateByUrl(detailsURL, { state: state });
                            }
                            else {
                                window.open(detailsURL, '_blank');
                            }
                            this.detailPositionService.setPositionData(this.activeListingIds);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingCardComponent.prototype.routeToDetailsPage = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, searchPageURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingService.checkListingDetailRoute(id, 'en').toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            searchPageURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                searchPageURL = this.i18nService.addCurrentLanguageToPath("/detail/" + id).split('/');
                            }
                            if (!searchPageURL[0].startsWith('/')) {
                                searchPageURL[0] = '/'.concat(searchPageURL[0]);
                            }
                            return [2 /*return*/, searchPageURL];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingCardComponent.prototype.getLargeImg = function (id) {
        return this.imageService.largeThumbnail(id);
    };
    ListingCardComponent.prototype.getFullImagePath = function (id) {
        return this.imageService.getFullImagePath(id);
    };
    ListingCardComponent.prototype.getPreviousPhoto = function () {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.listing.listingPhotos.length);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    };
    ListingCardComponent.prototype.getNextPhoto = function (id, $event) {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            if (index < _this.currentPhotoIndex) {
                _this.currentPhotoIndex = index;
            }
            else {
                if (_this.currentPhotoIndex === _this.listing.listingPhotos.length - 1 && _this.count == 0) {
                    _this.openCard(id, $event);
                }
                else {
                    if ($event.type === 'click') {
                        _this.photoSlider.slideNext();
                        _this.updatePhotoIndex();
                        _this.count = 1;
                    }
                    else {
                        if (_this.count == 1) {
                            _this.count = 0;
                        }
                        else {
                            _this.updatePhotoIndex();
                        }
                    }
                }
            }
        });
    };
    ListingCardComponent.prototype.updatePhotoIndex = function () {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            _this.currentPhotoIndex = index;
        });
    };
    ListingCardComponent.prototype.getUrlUpdate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lang = this.i18nService.getCurrentLanguage();
                        return [4 /*yield*/, this.listingService.checkListingDetailRoute(this.listing.id, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                        }
                        return [2 /*return*/, detailsURL];
                }
            });
        });
    };
    ListingCardComponent.prototype.shareLink = function ($event, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.stopImmediatePropagation();
                        this.userActivityService.trackListingShare(listingId);
                        return [4 /*yield*/, this.getUrlUpdate()];
                    case 1:
                        res = _a.sent();
                        this.ngNavigatorShareService
                            .share({
                            title: this.i18nService.getTranslation(this.listing.title),
                            text: this.i18nService.get('global.listing-card.shareLinkText'),
                            url: environment.spotServerUrl + res
                        })
                            .catch(function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var text, result, toast;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!error) return [3 /*break*/, 3];
                                        text = environment.spotServerUrl + res;
                                        result = this.copyTextToClipboard(text);
                                        if (!result) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.toastCtrl.create({
                                                message: this.i18nService.get('global.listing-card.desktopShareText'),
                                                duration: 3500,
                                                animated: true,
                                                keyboardClose: true,
                                                cssClass: 'toast-alert'
                                            })];
                                    case 1:
                                        toast = _a.sent();
                                        toast.onDidDismiss().then(function () { });
                                        return [4 /*yield*/, toast.present()];
                                    case 2: return [2 /*return*/, _a.sent()];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/, false];
                }
            });
        });
    };
    ListingCardComponent.prototype.copyTextToClipboard = function (text) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var txtArea, successful, msg;
            return tslib_1.__generator(this, function (_a) {
                txtArea = document.createElement('textarea');
                txtArea.id = 'txt';
                txtArea.style.position = 'fixed';
                txtArea.style.top = '0';
                txtArea.style.left = '0';
                txtArea.style.opacity = '0';
                txtArea.value = text;
                document.body.appendChild(txtArea);
                txtArea.select();
                try {
                    successful = document.execCommand('copy');
                    msg = successful ? 'successful' : 'unsuccessful';
                    if (successful) {
                        return [2 /*return*/, true];
                    }
                }
                catch (err) {
                }
                finally {
                    document.body.removeChild(txtArea);
                }
                return [2 /*return*/, false];
            });
        });
    };
    ListingCardComponent.prototype.addColon = function (propertySubTypes) {
        return propertySubTypes && propertySubTypes.length ? ':' : '';
    };
    ListingCardComponent.prototype.getMeasurementValue = function () {
        if (this.listing.measurementStandard) {
            return this.i18nService.getTranslation(this.listing.measurementStandard.measurement);
        }
        else {
            return this.i18nService.get('global.coworking.availableSpace');
            //return 'Available Space!';
        }
    };
    ListingCardComponent.prototype.hasSubtypeOnMobile = function () {
        return [
            this.isLoadFromMain,
            this.isMobile,
            this.listing,
            this.listing.propertySubTypes && this.listing.propertySubTypes.length
        ].every(function (condition) { return condition; });
    };
    ListingCardComponent.prototype.isCompanySameAsAUser = function (listing) {
        return listing && listing.offeredByUser.company.name === listing.offeredByUser.firstNameAndLastName;
    };
    ListingCardComponent.prototype.showPrevNextNav = function () {
        return [
            this.hasPointer,
            this.listing.listingPhotos && this.listing.listingPhotos.length > 1,
            !this.myListingPage
        ].every(function (condtion) { return condtion; });
    };
    ListingCardComponent.prototype.viewMap = function ($event) {
        $event.stopImmediatePropagation();
        this.updatePhotoIndex();
        this.photoSlider.slideTo(this.listing.listingPhotos.length);
    };
    ListingCardComponent.prototype.loopSlide = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var numberOfSlide, isBeginging, isEnd, prev;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        numberOfSlide = this.listing.listingPhotos.length;
                        return [4 /*yield*/, this.photoSlider.isBeginning()];
                    case 1:
                        isBeginging = _a.sent();
                        return [4 /*yield*/, this.photoSlider.isEnd()];
                    case 2:
                        isEnd = _a.sent();
                        return [4 /*yield*/, this.photoSlider.getPreviousIndex()];
                    case 3:
                        prev = _a.sent();
                        if (this.currentPhotoIndex === 0 && isBeginging) {
                            this.photoSlider.slideTo(this.listing.listingPhotos.length);
                        }
                        else if (this.currentPhotoIndex === numberOfSlide - 1 && isEnd) {
                            this.photoSlider.slideTo(0);
                        }
                        this.updatePhotoIndex();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingCardComponent.prototype.isRentCondoIPTUNegotiables = function () {
        return ((!this.listing.monthlyRent || this.listing.monthlyRent == 0) &&
            (!this.listing.monthlyCondoFee || this.listing.monthlyCondoFee == 0) &&
            (!this.listing.monthlyIptu || this.listing.monthlyIptu == 0));
    };
    ListingCardComponent.prototype.hasCoworkingSubType = function (listing) {
        return listing.isForLeaseSale === ListingOfferType.Coworking;
    };
    ListingCardComponent.prototype.setSharedPrivatePositionsText = function () {
        var showCoworkingPrivate = this.defaultCoworkingType && this.defaultCoworkingType == 'private';
        this.sharedPrivatePositionsText = this.coworkingService.getSharedPrivatePositionsText(this.listing, showCoworkingPrivate);
    };
    ListingCardComponent.prototype.checkRole = function (role) {
        try {
            var user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.log('roles undefined --> ', e);
        }
    };
    ListingCardComponent.prototype.buildListingDetailsURL = function (listingId) {
        return (environment.spotServerUrl + "/detail/" + listingId).concat(this.i18nService.getLanguagePathURL());
    };
    ListingCardComponent.prototype.changeSlide = function (event) {
        var swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
        this.listingSliderService.setSlide(swipeDirection);
    };
    return ListingCardComponent;
}());
export { ListingCardComponent };
