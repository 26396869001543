import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ImageService, PlatformHelperService, PropertyTypeHelperService, I18nService } from '@app/core';
import { AuthenticationService } from '@app/core/auth';
import { CommonGenericService } from '@app/core/common.service';
import { GenericMapService } from '@app/core/generic-map.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { AppCarouselRestoreService } from '@app/search-navigation/components/carousel';
import { AppListingSliderService } from '@app/search-navigation/components/listing-slider';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { faAward, faBuilding, faExternalLinkAlt, faHeart, faMapMarkerAlt, faShareAltSquare, faStar, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { IonSlides, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';
var SpotBuildingCardComponent = /** @class */ (function () {
    function SpotBuildingCardComponent(imageService, router, detailPositionService, carouselRestoreService, platformConfigHelper, dialog, commonService, commomMapSvc, breakpointObserver, propertyHelperService, i18nService, ngNavigatorShareService, toastCtrl, spotBuildingsService, auth, spacesService, _ts, userActivityService, coworkingService, listingSliderService) {
        var _this = this;
        this.imageService = imageService;
        this.router = router;
        this.detailPositionService = detailPositionService;
        this.carouselRestoreService = carouselRestoreService;
        this.platformConfigHelper = platformConfigHelper;
        this.dialog = dialog;
        this.commonService = commonService;
        this.commomMapSvc = commomMapSvc;
        this.breakpointObserver = breakpointObserver;
        this.propertyHelperService = propertyHelperService;
        this.i18nService = i18nService;
        this.ngNavigatorShareService = ngNavigatorShareService;
        this.toastCtrl = toastCtrl;
        this.spotBuildingsService = spotBuildingsService;
        this.auth = auth;
        this.spacesService = spacesService;
        this._ts = _ts;
        this.userActivityService = userActivityService;
        this.coworkingService = coworkingService;
        this.listingSliderService = listingSliderService;
        this.isMobile = false;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.currentPhotoIndex = 0;
        this.prevCurrentPhotoIndex = 0;
        this.counter = 0;
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: true,
            zoom: 15
        };
        this.photosSet = false;
        this.count = 0;
        this.notPhotos = false;
        this.faShareAltSquare = faShareAltSquare;
        this.screenWidth = window.innerWidth;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
            if (!_this.isMobile) {
                var mqStandAlone = '(display-mode: standalone)';
                if (window.matchMedia(mqStandAlone).matches) {
                    _this.isMobile = window.matchMedia(mqStandAlone).matches;
                }
            }
        });
    }
    SpotBuildingCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        var isOffice = this.commonService.isPropertyType(2001, this.listing);
        if (this.listing.buildingListingPhotos) {
            this.removeMap();
        }
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) { return (_this.isLoggedIn = val); });
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe(function (res) {
            _this.customLoginText = res;
        });
        this.hasPointer = window.matchMedia('(pointer: fine)').matches;
        this.faIcon =
            this.listing && this.listing.listingType && this.listing.listingType.toLowerCase() === 'featured'
                ? faStar
                : faAward;
        this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
        this.markers = [this.commonService.createMapMarker(this.listing)];
        this.staticMapImageUrl = this.commomMapSvc.generateStatiMapRequest(this.listing);
        this.notPhotos = this.doesNotPhotos();
        if (this.notPhotos) {
            this.spotImgURL = this.staticMapImageUrl ? this.staticMapImageUrl : this.defaultImage;
        }
        else {
            this.spotImgURL =
                this.listing.buildingListingPhotos[0] && this.listing.buildingListingPhotos[0].image
                    ? this.getMediumImg(this.getCroppedOrOriginalImage(this.listing.buildingListingPhotos[0]))
                    : this.staticMapImageUrl;
        }
        this.photosSet = true;
        this.faHeart = faHeart;
        this.fastar = faStar;
        this.faExternalLinkAlt = faExternalLinkAlt;
        this.faMarker = faMapMarkerAlt;
        this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(this.listing.buildingTypeId);
        this.spotBuildingTypeTranslation = this.propertyHelperService.getSpotBuildingTypeTranslation(this.listing.type);
        this.propertyTypeLbl = this.i18nService.get('global.menu.entities.buildingType') + ':';
        this.listingTypeLbl = this.i18nService.get('global.menu.entities.listingType') + ':';
        this.setSharedPrivatePositionsText();
        this.brokers = [];
        if (this.listing.brokers) {
            this.brokers = tslib_1.__spread(this.listing.brokers);
            if (this.brokers && this.brokers.length > 2) {
                var copy = this.listing.brokers.sort(function (a, b) { return (a != null ? a : Infinity) - (b != null ? b : Infinity); });
                if (copy && copy.length > 2) {
                    this.brokers = tslib_1.__spread(copy);
                    this.brokers.splice(0, copy.length - 2);
                }
            }
        }
        this.listing.availableAreaIni = 1000;
        this.listing.availableAreaEnd = 54000;
        this.spotName = this.i18nService.getTranslation(this.listing.buildingTitle);
    };
    SpotBuildingCardComponent.prototype.ngOnDestroy = function () {
        this.loginSubscription.unsubscribe();
        this.translateSubscription.unsubscribe();
    };
    SpotBuildingCardComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    SpotBuildingCardComponent.prototype.getHeaderCard = function () {
        return this.i18nService.getTranslation(this.listing.cityName);
    };
    SpotBuildingCardComponent.prototype.getCityState = function () {
        return this.i18nService.getTranslation(this.listing.cityName) + " - " + this.i18nService.getTranslation(this.listing.stateName);
    };
    SpotBuildingCardComponent.prototype.getTotalBuildingArea = function () {
        return 105000;
    };
    SpotBuildingCardComponent.prototype.doesNotPhotos = function () {
        if (!this.listing.buildingListingPhotos) {
            return true;
        }
        return this.listing.buildingListingPhotos.length == 0;
    };
    SpotBuildingCardComponent.prototype.removeMap = function () {
        var _this = this;
        Object.keys(this.listing.buildingListingPhotos).forEach(function (key) {
            if (_this.listing.buildingListingPhotos[key] && !_this.listing.buildingListingPhotos[key].image) {
                _this.listing.buildingListingPhotos.splice(key, 1);
            }
        });
    };
    SpotBuildingCardComponent.prototype.isEndOfSlide = function () {
        return this.currentPhotoIndex === this.listing.buildingListingPhotos.length - 1;
    };
    SpotBuildingCardComponent.prototype.getCroppedOrOriginalImage = function (listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    };
    SpotBuildingCardComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    SpotBuildingCardComponent.prototype.getCompanyLogoImg = function (id) {
        return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
    };
    SpotBuildingCardComponent.prototype.getTypeText = function () {
        return this.commonService.getTypeText(this.listing.type);
    };
    SpotBuildingCardComponent.prototype.slideToIndex = function (event, index) {
        event.stopPropagation();
        this.photoSlider.slideTo(index);
    };
    SpotBuildingCardComponent.prototype.openEditListingMenu = function (event, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                event.preventDefault();
                event.stopPropagation();
                console.log('Open Edit Listing Menu');
                //data = await this.listingService.getRevisionOrActiveVersionBasedOnUserRole(data);
                this.dialogRef = this.dialog.open(MyListingEditComponent, {
                    height: 'auto',
                    width: '550px',
                    data: data ? { data: data, myListingPage: this.myListingPage, date: new Date() } : null
                });
                this.dialogRef.afterClosed().subscribe(function (result) { });
                return [2 /*return*/];
            });
        });
    };
    SpotBuildingCardComponent.prototype.openPreviewComponent = function ($event) {
        $event.stopImmediatePropagation();
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.listing,
            panelClass: 'custom-modalbox'
        });
        this.dialogRef.afterClosed().subscribe(function (result) { });
    };
    SpotBuildingCardComponent.prototype.openCard = function (id, $event, externalLink) {
        if (externalLink === void 0) { externalLink = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, lang, res, detailsURL, spacesFilter;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = {
                            localStorageKey: this.localStorageKey,
                            searchCriteria: this.searchCriteria,
                            searchTags: this.searchTags,
                            checkBoxValues: this.checkBoxValues,
                            myListingAnalytics: this.myListingAnalytics,
                            myListingPage: this.myListingPage,
                            openUrl: true
                        };
                        $event.stopImmediatePropagation();
                        lang = this.i18nService.getCurrentLanguage();
                        if (window.location.href.includes('/es')) {
                            lang = 'es';
                        }
                        else if (window.location.href.includes('/en')) {
                            lang = 'en';
                        }
                        return [4 /*yield*/, this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                            if (externalLink) {
                                if (window.matchMedia('(display-mode: standalone)').matches) {
                                    this.router.navigateByUrl(detailsURL);
                                }
                                else {
                                    window.open(detailsURL, '_blank');
                                }
                                return [2 /*return*/];
                            }
                            spacesFilter = Object.assign({}, this.searchCriteria);
                            this.spacesService.saveSpacesFilter(spacesFilter);
                            if (this.isMobile) {
                                this.router.navigateByUrl(detailsURL, { state: state });
                            }
                            else {
                                if (window.matchMedia('(display-mode: standalone)').matches) {
                                    this.router.navigateByUrl(detailsURL);
                                }
                                else {
                                    window.open(detailsURL, '_blank');
                                }
                            }
                            this.detailPositionService.setPositionData(this.activeListingIds);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingCardComponent.prototype.routeToDetailsPage = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, searchPageURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, 'en').toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            searchPageURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                searchPageURL = this.i18nService.addCurrentLanguageToPath("/detail/" + id).split('/');
                            }
                            if (!searchPageURL[0].startsWith('/')) {
                                searchPageURL[0] = '/'.concat(searchPageURL[0]);
                            }
                            return [2 /*return*/, searchPageURL];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingCardComponent.prototype.getLargeImg = function (id) {
        return this.imageService.largeThumbnail(id);
    };
    SpotBuildingCardComponent.prototype.getFullImagePath = function (id) {
        return this.imageService.getFullImagePath(id);
    };
    SpotBuildingCardComponent.prototype.getPreviousPhoto = function () {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    };
    SpotBuildingCardComponent.prototype.getNextPhoto = function (id, $event) {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            if (index < _this.currentPhotoIndex) {
                _this.currentPhotoIndex = index;
            }
            else {
                if (_this.currentPhotoIndex === _this.listing.buildingListingPhotos.length - 1 && _this.count == 0) {
                    _this.openCard(id, $event);
                }
                else {
                    if ($event.type === 'click') {
                        _this.photoSlider.slideNext();
                        _this.updatePhotoIndex();
                        _this.count = 1;
                    }
                    else {
                        if (_this.count == 1) {
                            _this.count = 0;
                        }
                        else {
                            _this.updatePhotoIndex();
                        }
                    }
                }
            }
        });
    };
    SpotBuildingCardComponent.prototype.updatePhotoIndex = function () {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            _this.currentPhotoIndex = index;
        });
    };
    SpotBuildingCardComponent.prototype.getUrlUpdate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lang = this.i18nService.getCurrentLanguage();
                        return [4 /*yield*/, this.spotBuildingsService.getSpotBuildingDetailURLFormat(this.listing.id, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                        }
                        return [2 /*return*/, detailsURL];
                }
            });
        });
    };
    SpotBuildingCardComponent.prototype.shareLink = function ($event, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.stopImmediatePropagation();
                        this.userActivityService.trackListingShare(listingId);
                        return [4 /*yield*/, this.getUrlUpdate()];
                    case 1:
                        res = _a.sent();
                        this.ngNavigatorShareService
                            .share({
                            title: this.i18nService.getTranslation(this.listing.title),
                            text: this.i18nService.get('global.listing-card.shareLinkText'),
                            url: environment.spotServerUrl + res
                        })
                            .catch(function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var text, result, toast;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!error) return [3 /*break*/, 3];
                                        text = environment.spotServerUrl + res;
                                        result = this.copyTextToClipboard(text);
                                        if (!result) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.toastCtrl.create({
                                                message: this.i18nService.get('global.listing-card.desktopShareText'),
                                                duration: 3500,
                                                animated: true,
                                                keyboardClose: true,
                                                cssClass: 'toast-alert'
                                            })];
                                    case 1:
                                        toast = _a.sent();
                                        toast.onDidDismiss().then(function () { });
                                        return [4 /*yield*/, toast.present()];
                                    case 2: return [2 /*return*/, _a.sent()];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/, false];
                }
            });
        });
    };
    SpotBuildingCardComponent.prototype.copyTextToClipboard = function (text) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var txtArea, successful, msg;
            return tslib_1.__generator(this, function (_a) {
                txtArea = document.createElement('textarea');
                txtArea.id = 'txt';
                txtArea.style.position = 'fixed';
                txtArea.style.top = '0';
                txtArea.style.left = '0';
                txtArea.style.opacity = '0';
                txtArea.value = text;
                document.body.appendChild(txtArea);
                txtArea.select();
                try {
                    successful = document.execCommand('copy');
                    msg = successful ? 'successful' : 'unsuccessful';
                    if (successful) {
                        return [2 /*return*/, true];
                    }
                }
                catch (err) {
                }
                finally {
                    document.body.removeChild(txtArea);
                }
                return [2 /*return*/, false];
            });
        });
    };
    SpotBuildingCardComponent.prototype.addColon = function (propertySubTypes) {
        return propertySubTypes && propertySubTypes.length ? ':' : '';
    };
    SpotBuildingCardComponent.prototype.getMeasurementValue = function () {
        if (this.listing.measurementStandard) {
            return this.i18nService.getTranslation(this.listing.measurementStandard.measurement);
        }
        else {
            return this.i18nService.get('global.coworking.availableSpace');
            //return 'Available Space!';
        }
    };
    SpotBuildingCardComponent.prototype.hasSubtypeOnMobile = function () {
        return [
            this.isLoadFromMain,
            this.isMobile,
            this.listing
            //this.listing.propertySubTypes && this.listing.propertySubTypes.length
        ].every(function (condition) { return condition; });
    };
    SpotBuildingCardComponent.prototype.isCompanySameAsAUser = function (listing) {
        return listing && listing.offeredByUser.company.name === listing.offeredByUser.firstNameAndLastName;
    };
    SpotBuildingCardComponent.prototype.showPrevNextNav = function () {
        return [
            this.hasPointer,
            this.listing.buildingListingPhotos && this.listing.buildingListingPhotos.length > 1,
            !this.myListingPage
        ].every(function (condtion) { return condtion; });
    };
    SpotBuildingCardComponent.prototype.viewMap = function ($event) {
        $event.stopImmediatePropagation();
        this.updatePhotoIndex();
        this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
    };
    SpotBuildingCardComponent.prototype.loopSlide = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var numberOfSlide, isBeginging, isEnd, prev;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        numberOfSlide = this.listing.buildingListingPhotos.length;
                        return [4 /*yield*/, this.photoSlider.isBeginning()];
                    case 1:
                        isBeginging = _a.sent();
                        return [4 /*yield*/, this.photoSlider.isEnd()];
                    case 2:
                        isEnd = _a.sent();
                        return [4 /*yield*/, this.photoSlider.getPreviousIndex()];
                    case 3:
                        prev = _a.sent();
                        if (this.currentPhotoIndex === 0 && isBeginging) {
                            this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
                        }
                        else if (this.currentPhotoIndex === numberOfSlide - 1 && isEnd) {
                            this.photoSlider.slideTo(0);
                        }
                        this.updatePhotoIndex();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingCardComponent.prototype.isRentCondoIPTUNegotiables = function () {
        return false;
    };
    SpotBuildingCardComponent.prototype.hasCoworkingSubType = function (listing) {
        return listing.isForLeaseSale === ListingOfferType.Coworking;
    };
    SpotBuildingCardComponent.prototype.setSharedPrivatePositionsText = function () {
        var showCoworkingPrivate = this.defaultCoworkingType && this.defaultCoworkingType == 'private';
        this.sharedPrivatePositionsText = this.coworkingService.getSharedPrivatePositionsText(this.listing, showCoworkingPrivate);
    };
    SpotBuildingCardComponent.prototype.checkRole = function (role) {
        try {
            var user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.log('roles undefined --> ', e);
        }
    };
    SpotBuildingCardComponent.prototype.buildListingDetailsURL = function (listingId) {
        return (environment.spotServerUrl + "/detail/" + listingId).concat(this.i18nService.getLanguagePathURL());
    };
    SpotBuildingCardComponent.prototype.changeSlide = function (event) {
        var swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
        this.listingSliderService.setSlide(swipeDirection);
    };
    SpotBuildingCardComponent.prototype.getPropertyTypeText = function () {
        return this.commonService.getPropertyTypeText(this.listing.buildingTypeId);
    };
    SpotBuildingCardComponent.prototype.getAvailableAreaText = function () {
        return this.commonService.getAvailableAreaText(this.listing.spaceRangesDTO);
    };
    SpotBuildingCardComponent.prototype.getAvgAskingRent = function () {
        return this.commonService.getAvgAskingRent(this.listing, this.listing.type);
    };
    return SpotBuildingCardComponent;
}());
export { SpotBuildingCardComponent };
