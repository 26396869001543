import * as tslib_1 from "tslib";
import { I18nService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base-service';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
var SpotBuildingsService = /** @class */ (function (_super) {
    tslib_1.__extends(SpotBuildingsService, _super);
    function SpotBuildingsService(http, i18nService, _location, deviceDetectorService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.i18nService = i18nService;
        _this._location = _location;
        _this.deviceDetectorService = deviceDetectorService;
        // APIs
        _this.spotPhotosAPI = "/api/buildinglistingPhotos";
        _this.buildinglistingResource = "/api/buildinglisting";
        _this.detailUrlDataAPI = _this.buildinglistingResource + "/{id}/detail/url-data/{lang}";
        _this.logAPI = "/api/logs/log";
        _this.spotSetAPI = '/api/spotset/';
        _this.spotCover = '/api/spot-cover';
        _this.downloadSetReport = 'api/reports/files/';
        return _this;
    }
    SpotBuildingsService.prototype.logError = function (error) {
        var logMessage = { message: error };
        this.http
            .post(this.logAPI, logMessage)
            .toPromise()
            .then(function () { });
    };
    SpotBuildingsService.prototype.getPhotosFromSpot = function (id) {
        var url = _super.prototype.interporlateURL.call(this, this.spotPhotosAPI + "/:id", { id: id });
        return this.http.get(url, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    SpotBuildingsService.prototype.getSpotBuildingDetailURLFormat = function (id, lang) {
        var url = _super.prototype.interporlateURL.call(this, this.detailUrlDataAPI, { id: id, lang: lang });
        return this.http.get(url, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    SpotBuildingsService.prototype.getServiceProvider = function (spotId) {
        return this.http
            .get('api/service-provider/carousel-data?application=SPOT&size=5' + (spotId ? '&buildingListingId=' + spotId : ''))
            .pipe(map(function (val) { return val; }))
            .toPromise();
    };
    SpotBuildingsService.prototype.goToSpotDetailsURL = function (spotId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lang = this.i18nService.getCurrentLanguage();
                        if (window.location.href.includes('/es')) {
                            lang = 'es';
                        }
                        else if (window.location.href.includes('/en')) {
                            lang = 'en';
                        }
                        return [4 /*yield*/, this.getSpotBuildingDetailURLFormat(spotId, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                            this._location.go(detailsURL);
                            return [2 /*return*/, detailsURL];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsService.prototype.setSpotSpace = function (spotSet) {
        return this.http.post(this.spotSetAPI, spotSet).toPromise();
    };
    SpotBuildingsService.prototype.getSpotSet = function () {
        return this.http.get(this.spotSetAPI + "spot").toPromise();
    };
    SpotBuildingsService.prototype.putSpotSet = function (spotSet) {
        return this.http
            .put(this.spotSetAPI, spotSet, {
            observe: 'response'
        })
            .pipe(map(function (val) {
            return val.status;
        }))
            .toPromise()
            .catch(function (val) {
            return val.status;
        });
    };
    SpotBuildingsService.prototype.patchSpotSet = function (spotSet) {
        return this.http
            .patch(this.spotSetAPI, spotSet, {
            observe: 'response'
        })
            .pipe(map(function (val) {
            return val.status;
        }))
            .toPromise()
            .catch(function (val) {
            return val.status;
        });
    };
    SpotBuildingsService.prototype.getbuildinglistingSpaces = function (spaces) {
        return this.http.post(this.buildinglistingResource + "/spaces/spots", spaces).toPromise();
    };
    SpotBuildingsService.prototype.createReport = function (setReport) {
        return this.http.post(this.spotCover + "/report", setReport).toPromise();
    };
    SpotBuildingsService.prototype.downloadCoverReportt = function (fileName, isSafariIos) {
        this.downloadPDF(fileName, isSafariIos);
    };
    SpotBuildingsService.prototype.downloadPDF = function (fileName, isSafariIos) {
        if (isSafariIos || window.matchMedia('(display-mode: standalone)').matches) {
            if (this.deviceDetectorService.os.toLowerCase() === 'android') {
                var link = document.createElement('a');
                link.href = this.downloadSetReport + fileName;
                link.target = '_blank';
                link.dispatchEvent(new MouseEvent('click'));
            }
            else {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', this.downloadSetReport + fileName, true);
                xhr.responseType = 'blob';
                xhr.onload = function (e) {
                    if (this.status == 200) {
                        var myBlob = this.response;
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(myBlob);
                        link.target = '_blank';
                        link.download = fileName;
                        link.click();
                    }
                };
            }
            xhr.send();
        }
        else {
            var link = document.createElement('a');
            link.href = this.downloadSetReport + fileName;
            //link.download = fileName;
            link.target = '_blank';
            link.dispatchEvent(new MouseEvent('click'));
        }
    };
    SpotBuildingsService.prototype.excludeSpotDetails = function (ids) {
        return this.http.post(this.spotCover + "/config/validate", ids).toPromise();
    };
    return SpotBuildingsService;
}(BaseService));
export { SpotBuildingsService };
