import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { I18nService, ImageService, PlatformHelperService } from '@app/core';
import { SpotSet } from '@app/models/spotSet';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { AlertController } from '@ionic/angular';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SetReport } from '@app/models/set-report.model';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { DownloadFileService } from '@app/shared/services/downloadFile.service';
var ReportSpacesComponent = /** @class */ (function () {
    function ReportSpacesComponent(dialog, data, spotBuildingsService, alertCtrl, i18nService, imgService, nearbyListingSvc, platformHelpService, downloadFileService) {
        this.dialog = dialog;
        this.data = data;
        this.spotBuildingsService = spotBuildingsService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.imgService = imgService;
        this.nearbyListingSvc = nearbyListingSvc;
        this.platformHelpService = platformHelpService;
        this.downloadFileService = downloadFileService;
        this.spotSet = new SpotSet();
        this.listSpotSet = [];
        this.buildingDetails = [];
        this.step = 0;
        this.isMobile = false;
        this.panelOpenState = false;
        this.setReport = new SetReport();
        this.mapOptions = {};
        this.markers = [];
        this.ORANGE_PIN = this.nearbyListingSvc.orangePin;
        this.selectallSpaces = false;
        this.searching = false;
        this.errorReport = false;
        this.ids = [];
        this.contProperty = 0;
        this.contSpot = 0;
    }
    ReportSpacesComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        this.mapOptions = {
            zoom: 15,
            singleMarker: false,
            ignoreZoom: true,
            geopositionControl: false
        };
        this.chevronUp = faChevronUp;
        this.chevronDown = faChevronDown;
        if (this.data.ids) {
            this.ids = this.data.ids;
            this.setReport.reportName = this.data.name;
        }
        else {
            this.spacesParameters = this.data.data;
            this.spacesParameters.forEach(function (param) {
                _this.ids.push(param.id);
            });
        }
        this.getSpotsSet();
        if (this.ids.length > 0) {
            this.getSpotSpacesId(this.ids);
            this.excludeSpotDetails(this.ids);
        }
    };
    ReportSpacesComponent.prototype.excludeSpotDetails = function (ids) {
        var _this = this;
        this.spotBuildingsService.excludeSpotDetails(this.ids).then(function (val) {
            _this.excludeOptions = val;
            console.log(_this.excludeOptions);
        });
    };
    ReportSpacesComponent.prototype.checkOptions = function () {
        if (this.spotBuilding.type == 'L' && this.spotBuilding.building.buildingType.id == 2001) {
            if (this.excludeOptions.excludePropertyDetails.averageFloorSize) {
                this.setReport.includePropertyDetails.averageFloorSize = false;
            }
            else {
                this.setReport.includePropertyDetails.averageFloorSize = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.constructionStatus) {
                this.setReport.includePropertyDetails.constructionStatus = false;
            }
            else {
                this.setReport.includePropertyDetails.constructionStatus = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.numberOfFloors) {
                this.setReport.includePropertyDetails.numberOfFloors = false;
            }
            else {
                this.setReport.includePropertyDetails.numberOfFloors = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.sustainabilityCertification) {
                this.setReport.includePropertyDetails.sustainabilityCertification = false;
            }
            else {
                this.setReport.includePropertyDetails.sustainabilityCertification = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.twentyFourHourSecurity) {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = false;
            }
            else {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.yearBuilt) {
                this.setReport.includePropertyDetails.yearBuilt = false;
            }
            else {
                this.setReport.includePropertyDetails.yearBuilt = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludeSpotDetails.cellingHeight) {
                this.setReport.includeSpotDetails.cellingHeight = false;
            }
            else {
                this.setReport.includeSpotDetails.cellingHeight = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.maxContiguous) {
                this.setReport.includeSpotDetails.maxContiguous = false;
            }
            else {
                this.setReport.includeSpotDetails.maxContiguous = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.numberOfParkingSpaces) {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = true;
                this.contSpot++;
            }
        }
        if (this.spotBuilding.type == 'S' && this.spotBuilding.building.buildingType.id == 2001) {
            if (this.excludeOptions.excludePropertyDetails.averageFloorSize) {
                this.setReport.includePropertyDetails.averageFloorSize = false;
            }
            else {
                this.setReport.includePropertyDetails.averageFloorSize = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.constructionStatus) {
                this.setReport.includePropertyDetails.constructionStatus = false;
            }
            else {
                this.setReport.includePropertyDetails.constructionStatus = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.landArea) {
                this.setReport.includePropertyDetails.landArea = false;
            }
            else {
                this.setReport.includePropertyDetails.landArea = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.numberOfFloors) {
                this.setReport.includePropertyDetails.numberOfFloors = false;
            }
            else {
                this.setReport.includePropertyDetails.numberOfFloors = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.numberOfParkingSpaces) {
                this.setReport.includePropertyDetails.numberOfParkingSpaces = false;
            }
            else {
                this.setReport.includePropertyDetails.numberOfParkingSpaces = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.propertySize) {
                this.setReport.includePropertyDetails.propertySize = false;
            }
            else {
                this.setReport.includePropertyDetails.propertySize = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.sustainabilityCertification) {
                this.setReport.includePropertyDetails.sustainabilityCertification = false;
            }
            else {
                this.setReport.includePropertyDetails.sustainabilityCertification = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.twentyFourHourSecurity) {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = false;
            }
            else {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.yearBuilt) {
                this.setReport.includePropertyDetails.yearBuilt = false;
            }
            else {
                this.setReport.includePropertyDetails.yearBuilt = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludeSpotDetails.cellingHeight) {
                this.setReport.includeSpotDetails.cellingHeight = false;
            }
            else {
                this.setReport.includeSpotDetails.cellingHeight = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.maxContiguous) {
                this.setReport.includeSpotDetails.maxContiguous = false;
            }
            else {
                this.setReport.includeSpotDetails.maxContiguous = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.numberOfParkingSpaces) {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = true;
                this.contSpot++;
            }
        }
        if (this.spotBuilding.type == 'L' && this.spotBuilding.building.buildingType.id == 1001) {
            if (this.excludeOptions.excludePropertyDetails.constructionStatus) {
                this.setReport.includePropertyDetails.constructionStatus = false;
            }
            else {
                this.setReport.includePropertyDetails.constructionStatus = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.propertySubtype) {
                this.setReport.includePropertyDetails.propertySubtype = false;
            }
            else {
                this.setReport.includePropertyDetails.propertySubtype = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.twentyFourHourSecurity) {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = false;
            }
            else {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.yearBuilt) {
                this.setReport.includePropertyDetails.yearBuilt = false;
            }
            else {
                this.setReport.includePropertyDetails.yearBuilt = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludeSpotDetails.cellingHeight) {
                this.setReport.includeSpotDetails.cellingHeight = false;
            }
            else {
                this.setReport.includeSpotDetails.cellingHeight = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.coldStorage) {
                this.setReport.includeSpotDetails.coldStorage = false;
            }
            else {
                this.setReport.includeSpotDetails.coldStorage = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.directSublease) {
                this.setReport.includeSpotDetails.directSublease = false;
            }
            else {
                this.setReport.includeSpotDetails.directSublease = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.fullBuildOut) {
                this.setReport.includeSpotDetails.fullBuildOut = false;
            }
            else {
                this.setReport.includeSpotDetails.fullBuildOut = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.includesOffice) {
                this.setReport.includeSpotDetails.includesOffice = false;
            }
            else {
                this.setReport.includeSpotDetails.includesOffice = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.loadCapacity) {
                this.setReport.includeSpotDetails.loadCapacity = false;
            }
            else {
                this.setReport.includeSpotDetails.loadCapacity = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.numberOfDocks) {
                this.setReport.includeSpotDetails.numberOfDocks = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfDocks = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails) {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.numberOfTruckParkingSpaces) {
                this.setReport.includeSpotDetails.numberOfTruckParkingSpaces = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfTruckParkingSpaces = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.skyLights) {
                this.setReport.includeSpotDetails.skyLights = false;
            }
            else {
                this.setReport.includeSpotDetails.skyLights = true;
                this.contSpot++;
            }
        }
        if (this.spotBuilding.type == 'S' && this.spotBuilding.building.buildingType.id == 1001) {
            if (this.excludeOptions.excludePropertyDetails.constructionStatus) {
                this.setReport.includePropertyDetails.constructionStatus = false;
            }
            else {
                this.setReport.includePropertyDetails.constructionStatus = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.landArea) {
                this.setReport.includePropertyDetails.landArea = false;
            }
            else {
                this.setReport.includePropertyDetails.landArea = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.numberOfCarParkingSpaces) {
                this.setReport.includePropertyDetails.numberOfCarParkingSpaces = false;
            }
            else {
                this.setReport.includePropertyDetails.numberOfCarParkingSpaces = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.numberOfTruckParkingSpaces) {
                this.setReport.includePropertyDetails.numberOfTruckParkingSpaces = false;
            }
            else {
                this.setReport.includePropertyDetails.numberOfTruckParkingSpaces = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.propertySubtype) {
                this.setReport.includePropertyDetails.propertySubtype = false;
            }
            else {
                this.setReport.includePropertyDetails.propertySubtype = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.twentyFourHourSecurity) {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = false;
            }
            else {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.yearBuilt) {
                this.setReport.includePropertyDetails.yearBuilt = false;
            }
            else {
                this.setReport.includePropertyDetails.yearBuilt = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludeSpotDetails.cellingHeight) {
                this.setReport.includeSpotDetails.cellingHeight = false;
            }
            else {
                this.setReport.includeSpotDetails.cellingHeight = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.coldStorage) {
                this.setReport.includeSpotDetails.coldStorage = false;
            }
            else {
                this.setReport.includeSpotDetails.coldStorage = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.directSublease) {
                this.setReport.includeSpotDetails.directSublease = false;
            }
            else {
                this.setReport.includeSpotDetails.directSublease = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.fullBuildOut) {
                this.setReport.includeSpotDetails.fullBuildOut = false;
            }
            else {
                this.setReport.includeSpotDetails.fullBuildOut = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.includesOffice) {
                this.setReport.includeSpotDetails.includesOffice = false;
            }
            else {
                this.setReport.includeSpotDetails.includesOffice = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.includesRetail) {
                this.setReport.includeSpotDetails.includesRetail = false;
            }
            else {
                this.setReport.includeSpotDetails.includesRetail = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.loadCapacity) {
                this.setReport.includeSpotDetails.loadCapacity = false;
            }
            else {
                this.setReport.includeSpotDetails.loadCapacity = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.numberOfDocks) {
                this.setReport.includeSpotDetails.numberOfDocks = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfDocks = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails) {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfParkingSpaces = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.numberOfTruckParkingSpaces) {
                this.setReport.includeSpotDetails.numberOfTruckParkingSpaces = false;
            }
            else {
                this.setReport.includeSpotDetails.numberOfTruckParkingSpaces = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.skyLights) {
                this.setReport.includeSpotDetails.skyLights = false;
            }
            else {
                this.setReport.includeSpotDetails.skyLights = true;
                this.contSpot++;
            }
        }
        if (this.spotBuilding.type == 'C' && this.spotBuilding.building.buildingType.id == 2001) {
            if (this.excludeOptions.excludePropertyDetails.averageFloorSize) {
                this.setReport.includePropertyDetails.averageFloorSize = false;
            }
            else {
                this.setReport.includePropertyDetails.averageFloorSize = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.constructionStatus) {
                this.setReport.includePropertyDetails.constructionStatus = false;
            }
            else {
                this.setReport.includePropertyDetails.constructionStatus = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.numberOfFloors) {
                this.setReport.includePropertyDetails.numberOfFloors = false;
            }
            else {
                this.setReport.includePropertyDetails.numberOfFloors = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.sustainabilityCertification) {
                this.setReport.includePropertyDetails.sustainabilityCertification = false;
            }
            else {
                this.setReport.includePropertyDetails.sustainabilityCertification = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.twentyFourHourSecurity) {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = false;
            }
            else {
                this.setReport.includePropertyDetails.twentyFourHourSecurity = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.yearBuilt) {
                this.setReport.includePropertyDetails.yearBuilt = false;
            }
            else {
                this.setReport.includePropertyDetails.yearBuilt = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludePropertyDetails.yearBuilt) {
                this.setReport.includePropertyDetails.yearBuilt = false;
            }
            else {
                this.setReport.includePropertyDetails.yearBuilt = true;
                this.contProperty++;
            }
            if (this.excludeOptions.excludeSpotDetails.conferenceTimeAllowance) {
                this.setReport.includeSpotDetails.conferenceTimeAllowance = false;
            }
            else {
                this.setReport.includeSpotDetails.conferenceTimeAllowance = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.mailHandling) {
                this.setReport.includeSpotDetails.mailHandling = false;
            }
            else {
                this.setReport.includeSpotDetails.mailHandling = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.parkingAvailable) {
                this.setReport.includeSpotDetails.parkingAvailable = false;
            }
            else {
                this.setReport.includeSpotDetails.parkingAvailable = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.parkingCostPerMonth) {
                this.setReport.includeSpotDetails.parkingCostPerMonth = false;
            }
            else {
                this.setReport.includeSpotDetails.parkingCostPerMonth = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.printsAndCopiesPerMonth) {
                this.setReport.includeSpotDetails.printsAndCopiesPerMonth = false;
            }
            else {
                this.setReport.includeSpotDetails.printsAndCopiesPerMonth = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.privateOutdoorSpace) {
                this.setReport.includeSpotDetails.privateOutdoorSpace = false;
            }
            else {
                this.setReport.includeSpotDetails.privateOutdoorSpace = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.sharedOutdoorSpace) {
                this.setReport.includeSpotDetails.sharedOutdoorSpace = false;
            }
            else {
                this.setReport.includeSpotDetails.sharedOutdoorSpace = true;
                this.contSpot++;
            }
            if (this.excludeOptions.excludeSpotDetails.skyLights) {
                this.setReport.includeSpotDetails.skyLights = false;
            }
            else {
                this.setReport.includeSpotDetails.skyLights = true;
                this.contSpot++;
            }
        }
    };
    ReportSpacesComponent.prototype.getSpotSpacesId = function (ids) {
        var _this = this;
        this.spotBuildingsService.getbuildinglistingSpaces(ids).then(function (val) {
            _this.buildingDetails = val;
            _this.buildingDetails.forEach(function (param, index) {
                param.orderId = index + 1;
            });
            _this.generateNearByMarker();
            _this.selectAll();
        });
    };
    ReportSpacesComponent.prototype.changeSetSPot = function (event) {
        var spaceIds = JSON.parse(this.setSpotSpace.spotSetValue);
        var ids = Object.values(spaceIds);
        this.getSpotSpacesId(ids[0]);
        this.selectallSpaces = false;
        this.excludeSpotDetails(ids);
    };
    ReportSpacesComponent.prototype.getSpotsSet = function () {
        var _this = this;
        this.spotBuildingsService.getSpotSet().then(function (val) {
            _this.listSpotSet = val.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
            if (_this.ids.length > 0) {
                var currentSet = new SpotSet();
                currentSet.name = _this.i18nService.get('setReport.currentSelection');
                currentSet.spotSetValue = "{\"spaceIds\":[" + _this.ids + "]}";
                _this.setSpotSpace = currentSet;
                _this.listSpotSet.unshift(currentSet);
            }
        });
    };
    ReportSpacesComponent.prototype.changeStep = function (step) {
        this.step = step;
    };
    ReportSpacesComponent.prototype.close = function () {
        this.dialog.close();
    };
    ReportSpacesComponent.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    ReportSpacesComponent.prototype.getBuildingSelected = function () {
        var isSelected = this.buildingDetails.filter(function (x) { return x.selected == true; });
        if (isSelected.length > 0) {
            return false;
        }
        return true;
    };
    ReportSpacesComponent.prototype.reportSelection = function () {
        var _this = this;
        var isSelected = this.buildingDetails.filter(function (x) { return x.selected == true; });
        this.spotBuilding = isSelected[0];
        if (isSelected.length == 1) {
            this.setReport.spaceIds = isSelected[0].spaceIds;
        }
        else {
            isSelected.forEach(function (param) {
                param.spaceIds.forEach(function (x) {
                    _this.setReport.spaceIds.push(x);
                });
            });
        }
        this.changeStep(1);
        this.checkOptions();
        setTimeout(function () {
            _this.titleInput.nativeElement.focus();
        }, 200);
    };
    ReportSpacesComponent.prototype.generateNearByMarker = function () {
        var _this = this;
        this.markers = [];
        this.buildingDetails.forEach(function (param) {
            var currentListingMarker = _this.nearbyListingSvc.createBasicMarker(param.building, _this.ORANGE_PIN);
            _this.markers.push(currentListingMarker);
        });
    };
    ReportSpacesComponent.prototype.sendUp = function (element) {
        var index = this.buildingDetails.indexOf(element);
        if (index === 0) {
            return;
        }
        var aux = this.buildingDetails[index - 1];
        this.buildingDetails[index - 1] = element;
        this.buildingDetails[index - 1].orderId--;
        this.buildingDetails[index] = aux;
        this.buildingDetails[index].orderId++;
    };
    ReportSpacesComponent.prototype.sendDown = function (element) {
        var index = this.buildingDetails.indexOf(element);
        if (index === this.buildingDetails.length - 1) {
            return;
        }
        var aux = this.buildingDetails[index + 1];
        this.buildingDetails[index + 1] = element;
        this.buildingDetails[index + 1].orderId++;
        this.buildingDetails[index] = aux;
        this.buildingDetails[index].orderId--;
    };
    ReportSpacesComponent.prototype.createReport = function () {
        var _this = this;
        this.searching = true;
        this.setReport.lang = this.i18nService.getCurrentLanguage();
        this.spotBuildingsService
            .createReport(this.setReport)
            .then(function (val) {
            _this.dialog.close();
            _this.downloadFileService.downloadPDF('api/reports/files/', val.pdfFileName, _this.platformHelpService.isIosSafari());
        })
            .catch(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.searching = false;
                this.errorReport = true;
                setTimeout(function () {
                    _this.errorReport = false;
                }, 3500);
                return [2 /*return*/];
            });
        }); });
    };
    ReportSpacesComponent.prototype.changeOrientation = function () {
        this.setReport.formatting.tourBook = !this.setReport.formatting.tourBook;
    };
    ReportSpacesComponent.prototype.changeBuildingPage = function (opt) {
        if (opt == 1) {
            if (this.setReport.content.buildingPage.buildingPage) {
                this.setReport.content.buildingPage.buildingPage = false;
                this.setReport.content.buildingPage.allSpots = false;
                this.setReport.content.buildingPage.selectedSpotsOnly = false;
                this.resetPropertyDetails();
            }
            else {
                this.setReport.content.buildingPage.buildingPage = true;
                this.setReport.content.buildingPage.allSpots = true;
                this.setReport.content.buildingPage.selectedSpotsOnly = false;
            }
        }
        else {
            if (!this.setReport.content.buildingPage.allSpots && !this.setReport.content.buildingPage.selectedSpotsOnly) {
                this.setReport.content.buildingPage.buildingPage = true;
                return;
            }
            this.setReport.content.buildingPage.buildingPage = true;
            this.setReport.content.buildingPage.allSpots = !this.setReport.content.buildingPage.allSpots;
            this.setReport.content.buildingPage.selectedSpotsOnly = !this.setReport.content.buildingPage.selectedSpotsOnly;
        }
    };
    ReportSpacesComponent.prototype.resetPropertyDetails = function () {
        this.setReport.includePropertyDetails.propertySubtype = false;
        this.setReport.includePropertyDetails.yearBuilt = false;
        this.setReport.includePropertyDetails.constructionStatus = false;
        this.setReport.includePropertyDetails.propertySize = false;
        this.setReport.includePropertyDetails.numberOfFloors = false;
        this.setReport.includePropertyDetails.privateOutdoorSpace = false;
        this.setReport.includePropertyDetails.sharedOutdoorSpace = false;
        this.setReport.includePropertyDetails.parkingAvailable = false;
        this.setReport.includePropertyDetails.parkingCostPerMonth = false;
        this.setReport.includePropertyDetails.printsAndCopiesPerMonth = false;
        this.setReport.includePropertyDetails.mailHanding = false;
        this.setReport.includePropertyDetails.numberOfCarParkingSpaces = false;
        this.setReport.includePropertyDetails.numberOfTruckParkingSpaces = false;
        this.setReport.includePropertyDetails.landArea = false;
        this.setReport.includePropertyDetails.sustainabilityCertification = false;
        this.setReport.includePropertyDetails.twentyFourHourSecurity = false;
        this.setReport.includePropertyDetails.numberOfParkingSpaces = false;
    };
    ReportSpacesComponent.prototype.changeSpotPage = function (opt) {
        if (opt == 1) {
            if (this.setReport.content.spotPage.spotPage) {
                this.setReport.content.spotPage.spotPage = false;
                this.setReport.content.spotPage.allSpots = false;
                this.setReport.content.spotPage.selectedSpotsOnly = false;
                this.resetSpotDetails();
            }
            else {
                this.setReport.content.spotPage.spotPage = true;
                this.setReport.content.spotPage.allSpots = true;
                this.setReport.content.spotPage.selectedSpotsOnly = false;
            }
        }
        else {
            if (!this.setReport.content.spotPage.allSpots && !this.setReport.content.spotPage.selectedSpotsOnly) {
                this.setReport.content.spotPage.spotPage = true;
                return;
            }
            this.setReport.content.spotPage.spotPage = true;
            this.setReport.content.spotPage.allSpots = !this.setReport.content.spotPage.allSpots;
            this.setReport.content.spotPage.selectedSpotsOnly = !this.setReport.content.spotPage.selectedSpotsOnly;
        }
    };
    ReportSpacesComponent.prototype.resetSpotDetails = function () {
        this.setReport.includeSpotDetails.numberOfDocks = false;
        this.setReport.includeSpotDetails.numberOfTruckParkingSpaces = false;
        this.setReport.includeSpotDetails.loadCapacity = false;
        this.setReport.includeSpotDetails.numberOfParkingSpaces = false;
        this.setReport.includeSpotDetails.skyLights = false;
        this.setReport.includeSpotDetails.cellingHeight = false;
        this.setReport.includeSpotDetails.includesOffice = false;
        this.setReport.includeSpotDetails.includesRetail = false;
        this.setReport.includeSpotDetails.coldStorage = false;
        this.setReport.includeSpotDetails.fullBuildOut = false;
        this.setReport.includeSpotDetails.directSublease = false;
        this.setReport.includeSpotDetails.maxContiguous = false;
        this.setReport.includeSpotDetails.conferenceTimeAllowance = false;
        this.setReport.includeSpotDetails.privateOutdoorSpace = false;
        this.setReport.includeSpotDetails.sharedOutdoorSpace = false;
        this.setReport.includeSpotDetails.parkingAvailable = false;
        this.setReport.includeSpotDetails.parkingCostPerMonth = false;
        this.setReport.includeSpotDetails.printsAndCopiesPerMonth = false;
        this.setReport.includeSpotDetails.mailHandling = false;
    };
    ReportSpacesComponent.prototype.selectAll = function () {
        this.selectallSpaces = !this.selectallSpaces;
        if (this.selectallSpaces) {
            this.buildingDetails.map(function (val) { return (val.selected = true); });
        }
        else {
            this.buildingDetails.map(function (val) { return (val.selected = false); });
        }
    };
    return ReportSpacesComponent;
}());
export { ReportSpacesComponent };
