import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { AccountService } from './account.service';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ImageService } from '../image.service';
import { WebsocketService, SiilaBusAuthActions } from '@app/websockets';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { I18nService } from '../i18n.service';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, platformId, account, registerService, router, alertCtrl, imageservice, websocketService, myMessageService, socketService, i18nService, listingSearchService) {
        this.http = http;
        this.platformId = platformId;
        this.account = account;
        this.registerService = registerService;
        this.router = router;
        this.alertCtrl = alertCtrl;
        this.imageservice = imageservice;
        this.websocketService = websocketService;
        this.myMessageService = myMessageService;
        this.socketService = socketService;
        this.i18nService = i18nService;
        this.listingSearchService = listingSearchService;
        this.host = 'host';
        this.dev = '/oauth/token';
        this.sessionPersistenceEndpoint = '/api/authenticate';
        this.$isLoginSubject = new BehaviorSubject(this.hasToken());
        this.loadUnreadMessages();
        window.addEventListener('storage', this.message_receive);
    }
    AuthenticationService.prototype.message_broadcast = function (message) {
        localStorage.setItem('message', message);
        localStorage.removeItem('message');
    };
    AuthenticationService.prototype.message_receive = function (ev) {
        if (ev.key == 'message') {
            var message = ev.newValue;
            if (message && (message == 'logout' || message == 'login')) {
                console.log('Received login/logout cross-tab call');
                window.location.href = environment.spotServerUrl;
            }
        }
    };
    AuthenticationService.prototype.loadUnreadMessages = function () {
        if (localStorage.getItem('unreadCount')) {
            this.myMessageService.setUnreadCount(parseInt(localStorage.getItem('unreadCount')));
        }
    };
    /**
     * if we have token the user is loggedIn
     * @returns {boolean}
     */
    AuthenticationService.prototype.hasToken = function () {
        return !!localStorage.getItem('token');
    };
    AuthenticationService.prototype.getToken = function () {
        return localStorage.getItem('token') || '';
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        return this.$isLoginSubject.asObservable();
    };
    AuthenticationService.prototype.isSessionDestroyed = function () {
        return this.http.get(this.sessionPersistenceEndpoint);
    };
    AuthenticationService.prototype.submitLoginCredentials = function (username, password, keepUrlOnBadCredentials) {
        var _this = this;
        if (keepUrlOnBadCredentials === void 0) { keepUrlOnBadCredentials = false; }
        var body = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('secret', 'mySecretOAuthSecret')
            .set('grant_type', 'password')
            //.set('scope', 'read write')
            .set('scope', 'spot')
            .set('client_id', 'siilaapp');
        return this.http
            .post(this.dev, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/json')
                .set('Authorization', 'Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA==')
        })
            .toPromise()
            .then(function (res) {
            if (isPlatformBrowser(_this.platformId)) {
                localStorage.setItem('token', JSON.stringify(res));
            }
            _this.$isLoginSubject.next(true);
            return;
        })
            .then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var account, userPhotos, image, profileImage, tokenValue, user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.account.getAccount().toPromise()];
                    case 1:
                        account = _a.sent();
                        return [4 /*yield*/, this.account.getUserPhotoList().toPromise()];
                    case 2:
                        userPhotos = _a.sent();
                        image = this.account.getUploadedImageId(userPhotos);
                        profileImage = this.imageservice.mediumThumbnail(image.profileImageId);
                        localStorage.setItem('profileImage', profileImage);
                        localStorage.setItem('unreadCount', this.myMessageService.getUnreadMessages() + '');
                        localStorage.setItem('user', JSON.stringify(account));
                        this.account.currentUser$.next(account);
                        if (account && account.company && account.company.avoidPiwikTracking === true) {
                            localStorage.setItem('userType', 'SiiLA');
                        }
                        else {
                            localStorage.setItem('userType', 'Customer');
                        }
                        localStorage.setItem('userCompany', account.company.name);
                        this.listingSearchService.restoreBackSelectedFiltersFrom(SelectedFilterKey.MY_LISTINGS, account);
                        this.listingSearchService.restoreBackSelectedFiltersFrom(SelectedFilterKey.LISTING_ANALYTICS, account);
                        sessionStorage.removeItem(SelectedFilterKey.SPOT_BUILDING_SEARCH);
                        sessionStorage.removeItem(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS);
                        tokenValue = JSON.parse(localStorage.getItem('token'));
                        user = account;
                        this.userAccount = account;
                        this.websocketService.send(SiilaBusAuthActions.AUTHORIZE, { tokenValue: tokenValue, user: user });
                        this.socketService.updateUnreadMessageCount();
                        this.socketService.setCurrentUser();
                        this.socketService.connect();
                        this.message_broadcast('login');
                        // const userDevices = await this.deviceRegistration.retrieveUserDevices();
                        return [2 /*return*/, {
                                account: account,
                                // devices: userDevices,
                                registrationRequired: account.deviceRegistrationRequired
                            }];
                }
            });
        }); })
            .catch(function (e) {
            var errorDescription = e.error && (e.error.error_description || 'error');
            switch (e.error.error_description) {
                case 'LOCKED_ACCOUNT':
                    console.log('LOCKED');
                    _this.showMessage('Account Locked', 'Your account has been locked.');
                    break;
                case 'INACTIVE_ACCOUNT':
                    console.log('INACTIVE');
                    _this.showMessage('Inactive Account/Company', 'Either your company or your account has been deactivated.');
                    break;
                case 'INACTIVE_SPOT_ACCOUNT':
                    var inactiveAccount = _this.i18nService.get('global.inactiveAccount');
                    var accountConfirmation = _this.i18nService.get('global.AccountConfirmation');
                    _this.showMessage(inactiveAccount, accountConfirmation, false, 'email-confirm-height');
                    break;
                default:
                    if (e.error.error_description.slice(0, 22) == 'SHOULD_CHANGE_PASSWORD') {
                        _this.registerService.setKeyForChangePassword(e.error.error_description.split(':')[1]);
                        _this.router.navigateByUrl('/change-password');
                    } /* else {
                  const badCredentialsMsg = this.i18nService.get('global.badCredentials');
                  const badCredentialsDesc = this.i18nService.get('global.badCredentialsDesc');
                  this.showMessage(badCredentialsMsg, badCredentialsDesc, keepUrlOnBadCredentials);
                } */
            }
            return {
                error: errorDescription
            };
        });
    };
    AuthenticationService.prototype.refreshAccessToken = function () {
        var refreshToken = this.getToken() ? JSON.parse(this.getToken()).refresh_token : null;
        if (!refreshToken) {
            console.info(this.i18nService.get('TokenExpiration.noAccessTokenMsg'));
        }
        var body = new HttpParams()
            .set('secret', 'mySecretOAuthSecret')
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken)
            .set('scope', 'spot')
            .set('client_id', 'siilaapp');
        return this.http
            .post(this.dev, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/json')
                .set('Authorization', 'Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA==')
        })
            .pipe(tap(function (authData) {
            localStorage.setItem('token', JSON.stringify(authData));
        }), map(function (authData) { return authData.access_token; }));
    };
    AuthenticationService.prototype.showMessage = function (header, message, keepUrl, cssClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var okText, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        okText = this.i18nService.get('global.OK');
                        return [4 /*yield*/, this.alertCtrl.create({
                                cssClass: cssClass,
                                header: header,
                                message: message,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            if (!keepUrl) {
                                _this.router.navigateByUrl('/');
                            }
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.completeLoginProcess = function (account) {
        if (account && account.roles.includes('ROLE_LISTING')) {
            // this.dialog.close();
            // this.router.navigate(["listings"]);
            //this.broadcastChannel.postMessage({ payload: 'LOGIN' });
        }
        else {
            // this.windowRef.nativeWindow.location.href = this.windowRef.nativeWindow.location.hostname.includes(
            //   "localhost"
            // )
            //   ? `http://localhost:8080/#!/community`
            //   : `https://devapp.siila.com.br/#!/community`;
        }
    };
    AuthenticationService.prototype.registerDevice = function (account) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isPlatformBrowser(this.platformId)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.http.post(environment.apiURL + "/logout", {}).toPromise()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error('Terminating db session failed.', e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.removeCredentials();
                        this.message_broadcast('logout');
                        //this.broadcastChannel.postMessage('logout'); /* send */
                        this.websocketService.send(SiilaBusAuthActions.KICK, JSON.stringify({ action: 'kick', data: 'kick' }));
                        this.socketService.disconnect();
                        if (this.router.url !== '/' && this.router.url !== '/') {
                            this.router.navigate(['/']);
                        }
                        else {
                            location.reload();
                        }
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.removeCredentials = function () {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('ls.token');
        localStorage.removeItem(SelectedFilterKey.FAVORITES_SEARCH);
        this.account.accountLogout();
        this.$isLoginSubject.next(false);
    };
    return AuthenticationService;
}());
export { AuthenticationService };
export var RegistrationSteps;
(function (RegistrationSteps) {
    RegistrationSteps["New"] = "new";
    RegistrationSteps["Existing"] = "existing";
    RegistrationSteps["Success"] = "success";
})(RegistrationSteps || (RegistrationSteps = {}));
