import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { CommonGenericService } from '@app/core/common.service';
import { I18nService } from '@app/core/i18n.service';
import { SpotBuildingSearchRequest } from '../../models/spot-buildings/spot-building-search.model';
import { SpotBuildingsSearchService } from '../../spot-buildings/services/spot-buildings-search.service';
import { LocationService } from '../services/location.service';
import { EventEmitter } from '@angular/core';
import { AppSpotCardSlider } from '../spot-card-slider/spot-card-slider';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { TRANSACTION_TYPES } from '@app/models/transaction-type.model';
var SpotCardTabsComponent = /** @class */ (function () {
    function SpotCardTabsComponent(spotBuildingSearchService, commonService, locationService, i18NService, breakpointObserver, routingHelperService, cdr) {
        var _this = this;
        this.spotBuildingSearchService = spotBuildingSearchService;
        this.commonService = commonService;
        this.locationService = locationService;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.routingHelperService = routingHelperService;
        this.cdr = cdr;
        this.isFavorite = false;
        this.totalCountEvent = new EventEmitter();
        this.totalSpotBuildingsCount = 0;
        this.allSpotBuildings = [];
        this.currentPage = 1;
        this.pageSize = 12;
        this.currentCityFilterId = null;
        this.currentSpotBuildingType = 'L';
        this.tabIndex = 0;
        this.searching = false;
        this.isMobile = false;
        this.searchingMobile = false;
        this.currentPhotoIndex = 0;
        this.firstTime = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    SpotCardTabsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInkBar();
        }, 500);
    };
    SpotCardTabsComponent.prototype.ngAfterContentChecked = function () {
        this.cdr.detectChanges();
    };
    SpotCardTabsComponent.prototype.updateInkBar = function () {
        if (this.tabGroup) {
            this.tabGroup.realignInkBar();
        }
    };
    SpotCardTabsComponent.prototype.ngOnInit = function () {
        this.faHeart = faHeart;
        this.setSearching(true);
        this.countSpotsByType();
        this.allStateFilter = {
            id: null,
            name: this.i18NService.get('All')
        };
        this.cityFilters = [];
        this.forLeaseLbl = this.i18NService.get(TRANSACTION_TYPES[0].name);
        this.forSaleLbl = this.i18NService.get(TRANSACTION_TYPES[2].name);
        this.forCoworkingLbl = 'Coworking';
    };
    SpotCardTabsComponent.prototype.countSpotsByType = function () {
        var _this = this;
        this.spotBuildingSearchService.countSpotsByType(this.spotBuildingSearchRequest).then(function (spotByTypes) {
            if (spotByTypes) {
                _this.showForLeaseTab = spotByTypes['L'] ? spotByTypes['L'] > 0 : false;
                _this.showForSaleTab = spotByTypes['S'] ? spotByTypes['S'] > 0 : false;
                _this.showForCoworkingTab = spotByTypes['C'] ? spotByTypes['C'] > 0 : false;
                _this.currentSpotBuildingType = _this.showForLeaseTab
                    ? 'L'
                    : _this.showForSaleTab
                        ? 'S'
                        : _this.showForCoworkingTab
                            ? 'C'
                            : null;
                if (!_this.currentSpotBuildingType) {
                    _this.setSearching(false);
                    return;
                }
                _this.firstTime = true;
                if (!_this.commonService.isMobileWidth()) {
                    _this.fetchSpots(_this.currentSpotBuildingType, _this.currentPage);
                }
                else {
                    _this.updatePayload(_this.currentSpotBuildingType, null);
                }
            }
        });
    };
    SpotCardTabsComponent.prototype.changeSpotType = function (type) {
        this.currentSpotBuildingType = type;
        this.clearSearch();
        if (this.isMobile) {
            this.updatePayload(this.currentSpotBuildingType, null);
            this.findStatesByRequestFilter(this.currentSpotBuildingType);
        }
        else {
            this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
        }
    };
    SpotCardTabsComponent.prototype.tabChanged = function (tabChangeEvent) {
        this.setSearching(true);
        this.tabIndex = tabChangeEvent.index;
        this.currentSpotBuildingType =
            tabChangeEvent.tab.textLabel == this.forLeaseLbl
                ? 'L'
                : tabChangeEvent.tab.textLabel == this.forSaleLbl
                    ? 'S'
                    : tabChangeEvent.tab.textLabel == this.forCoworkingLbl
                        ? 'C'
                        : null;
        this.cityFilters = [];
        this.currentCityFilterId = this.allStateFilter.id;
        this.clearSearch();
        if (this.isMobile) {
            this.updatePayload(this.currentSpotBuildingType, null);
            this.findStatesByRequestFilter(this.currentSpotBuildingType);
        }
        else {
            this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
        }
    };
    SpotCardTabsComponent.prototype.clearSearch = function () {
        this.currentPage = 1;
        this.allSpotIds = [];
        this.allSpotBuildings = [];
    };
    SpotCardTabsComponent.prototype.applyCityFilter = function (stateId) {
        var _this = this;
        this.currentCityFilterId = stateId;
        this.setSearching(true);
        this.findAllSpotBuildingIds(this.currentSpotBuildingType, stateId).then(function (result) {
            _this.allSpotIds = result.body;
            _this.totalSpotBuildingsCount = _this.allSpotIds ? _this.allSpotIds.length : 0;
            _this.getSpotBuildingsPaginated(_this.allSpotIds, _this.currentPage, false);
        });
    };
    SpotCardTabsComponent.prototype.applyStateFilterOnMobile = function (event, stateId, sliderIndex) {
        this.currentCityFilterId = stateId;
        this.setSearching(true);
        if (sliderIndex >= 0) {
            this.currentPhotoIndex = sliderIndex;
            this.finishSearch();
        }
        this.updatePayload(this.currentSpotBuildingType, stateId);
    };
    SpotCardTabsComponent.prototype.updatePayload = function (spotBuildingType, stateId) {
        this.spotBuildingSearchRequest = tslib_1.__assign({}, this.spotBuildingSearchRequest, { stateId: stateId, listingLeaseOrSale: tslib_1.__spread(spotBuildingType) });
    };
    SpotCardTabsComponent.prototype.showMoreResults = function () {
        return this.totalSpotBuildingsCount - this.currentPage * this.pageSize > 0;
    };
    SpotCardTabsComponent.prototype.showMoreResultsAction = function () {
        this.currentPage++;
        this.getSpotBuildingsPaginated(this.allSpotIds, this.currentPage, true);
    };
    SpotCardTabsComponent.prototype.updateCounts = function (total) {
        if (total) {
            this.totalSpotBuildingsCount = total;
        }
        else {
            this.totalSpotBuildingsCount = this.allSpotIds ? this.allSpotIds.length : 0;
        }
        this.totalCountEvent.emit(this.totalSpotBuildingsCount);
    };
    SpotCardTabsComponent.prototype.spotsCountSlider = function (total) {
        this.finishSearch(total);
    };
    SpotCardTabsComponent.prototype.fetchSpots = function (spotBuildingType, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setSearching(true);
                        return [4 /*yield*/, this.findAllSpotBuildingIds(spotBuildingType).then(function (result) {
                                _this.allSpotIds = result.body;
                                _this.getSpotBuildingsPaginated(_this.allSpotIds, page, false);
                            })];
                    case 1:
                        _a.sent();
                        this.findStatesByRequestFilter(spotBuildingType);
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotCardTabsComponent.prototype.findStatesByRequestFilter = function (spotBuildingType) {
        var _this = this;
        this.getStatesByRequestFilter(spotBuildingType).then(function (states) {
            _this.cityFilters = [];
            if (states && states.length > 0) {
                states = states.map(function (state) {
                    var stateName = _this.getDefaultTranslation(state.name);
                    state.name = stateName;
                    return state;
                });
                _this.cityFilters.push(_this.allStateFilter);
                states = _this.locationService.putRioAndSaoPauloOnTop(states);
                if (states.length > 5) {
                    states = states.slice(0, 5);
                }
                _this.cityFilters = tslib_1.__spread(states);
                _this.cityFilters.unshift(_this.allStateFilter);
                _this.currentPhotoIndex = 0;
            }
        });
    };
    SpotCardTabsComponent.prototype.getStatesByRequestFilter = function (spotBuildingType) {
        if (this.portfolioFor == 'BROKER_DETAIL') {
            return this.locationService.getStatesByBrokerIdAndSpotBuildingType(this.spotBuildingSearchRequest.brokerId, spotBuildingType);
        }
        if (this.portfolioFor == 'COMPANY_DETAIL') {
            return this.locationService.getStatesByCompanyAndSpotBuildingType(this.spotBuildingSearchRequest.companyId, spotBuildingType);
        }
        return new Promise(function () { return []; });
    };
    SpotCardTabsComponent.prototype.getDefaultTranslation = function (translationArtifact) {
        try {
            return this.i18NService.getTranslation(translationArtifact);
        }
        catch (e) {
            console.log(e);
        }
    };
    SpotCardTabsComponent.prototype.findAllSpotBuildingIds = function (spotBuildingType, stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (spotBuildingType && spotBuildingType == 'L' && this.isFavoriteSearch()) {
                            this.spotBuildingSearchRequest.listingLeaseOrSale = tslib_1.__spread(spotBuildingType, ['C']);
                        }
                        else {
                            this.spotBuildingSearchRequest.listingLeaseOrSale = tslib_1.__spread(spotBuildingType);
                        }
                        this.spotBuildingSearchRequest.stateId = stateId;
                        this.clearSearch();
                        return [4 /*yield*/, this.spotBuildingSearchService
                                .searchSpotBuildingIds(this.spotBuildingSearchRequest, 1)
                                .then(function (spotBulidingIdsResponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    return [2 /*return*/, spotBulidingIdsResponse];
                                });
                            }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpotCardTabsComponent.prototype.getSpotBuildingsPaginated = function (spotIds, page, concatNewResult) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!spotIds || spotIds.length == 0) {
                            this.allSpotBuildings = [];
                            this.finishSearch();
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.spotBuildingSearchService.getSpotBuildingDetails(page, this.pageSize, spotIds).then(function (result) {
                                if (concatNewResult) {
                                    var newListings = result;
                                    if (newListings && newListings.length) {
                                        _this.allSpotBuildings = _this.allSpotBuildings.concat(newListings);
                                    }
                                }
                                else {
                                    _this.allSpotBuildings = result;
                                }
                                _this.sortSpots();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotCardTabsComponent.prototype.finishSearch = function (total) {
        var _this = this;
        console.log('Finish Search ', total);
        this.updateCounts(total);
        setTimeout(function () {
            _this.setSearching(false);
            _this.firstTime = false;
        }, 100);
    };
    SpotCardTabsComponent.prototype.sortSpots = function () {
        if (!this.getCurrentSort()) {
            this.allSpotBuildings = this.allSpotBuildings.sort(function (first, second) { return Number(first.premium) - Number(second.premium); });
        }
        this.finishSearch();
    };
    SpotCardTabsComponent.prototype.isMobileDevice = function () {
        return this.isMobile && this.spotCardSlider;
    };
    SpotCardTabsComponent.prototype.areThereSpotBuildings = function () {
        if (this.isMobileDevice()) {
            return this.spotCardSlider.allSpotIds && this.spotCardSlider.allSpotIds.length > 0;
        }
        return this.allSpotBuildings && this.allSpotBuildings.length > 0;
    };
    SpotCardTabsComponent.prototype.showNoFoundSection = function () {
        return !this.areThereSpotBuildings() && this.isFavoriteSearch();
    };
    SpotCardTabsComponent.prototype.isFavoriteSearch = function () {
        return this.portfolioFor && this.portfolioFor == 'FAVORITE_SEARCH';
    };
    SpotCardTabsComponent.prototype.isSearching = function () {
        if (this.isMobile) {
            return this.searchingMobile;
        }
        return this.searching;
    };
    SpotCardTabsComponent.prototype.goToSearch = function () {
        this.routingHelperService.navigateToSearchPage(false);
    };
    SpotCardTabsComponent.prototype.applySortBy = function (type, direction) {
        var _this = this;
        if (this.allSpotIds && this.allSpotIds.length > 0) {
            var sortDTO = {
                sortBy: type,
                direction: direction,
                spotBuildingType: this.currentSpotBuildingType,
                spotIds: tslib_1.__spread(this.allSpotIds),
                currencyType: null
            };
            this.setCurrentSort(sortDTO);
            if (sortDTO.spotIds) {
                this.spotBuildingSearchService.sortCurrentSpotBuildingIds(sortDTO).then(function (result) {
                    var sortedIds = result.body;
                    _this.getSpotBuildingsPaginated(sortedIds, 1, false);
                });
            }
        }
    };
    SpotCardTabsComponent.prototype.isActiveSort = function (type, direction) {
        var currentSortFilter = this.getCurrentSort();
        if (currentSortFilter) {
            return currentSortFilter.sortBy == type && currentSortFilter.direction == direction;
        }
        return false;
    };
    SpotCardTabsComponent.prototype.clearSortSelection = function () {
        this.setCurrentSort(undefined);
        this.getSpotBuildingsPaginated(this.allSpotIds, 1, false);
    };
    SpotCardTabsComponent.prototype.setCurrentSort = function (sortBy) {
        console.log('Modified Sort');
        this.spotBuildingSearchRequest.sortSpotsBy = sortBy;
    };
    SpotCardTabsComponent.prototype.getCurrentSort = function () {
        return this.spotBuildingSearchRequest.sortSpotsBy;
    };
    SpotCardTabsComponent.prototype.getHeaderResults = function () {
        return this.totalSpotBuildingsCount + " " + this.i18NService.get('global.savedAsFav');
    };
    SpotCardTabsComponent.prototype.hideFavoriteIcon = function () {
        return false;
    };
    SpotCardTabsComponent.prototype.favoriteChange = function (changedFavorite) {
        if (this.onlyFavorites) {
            this.keepFavoriteSpots(changedFavorite);
            this.updateCounts();
        }
    };
    SpotCardTabsComponent.prototype.keepFavoriteSpots = function (updatedFavorite) {
        this.allSpotBuildings = this.allSpotBuildings.filter(function (spot) { return spot.favorite; });
        if (updatedFavorite && updatedFavorite.id) {
            this.allSpotIds = this.allSpotIds.filter(function (spotId) { return spotId != updatedFavorite.id; });
        }
    };
    SpotCardTabsComponent.prototype.setSearching = function (value) {
        if (this.isMobile) {
            this.searchingMobile = value;
        }
        else {
            this.searching = value;
        }
    };
    return SpotCardTabsComponent;
}());
export { SpotCardTabsComponent };
