import * as tslib_1 from "tslib";
import { Meta, Title } from '@angular/platform-browser';
import { CommonGenericService } from '../common.service';
import { PropertyTypeHelperService } from '../helpers';
import { I18nService } from '../i18n.service';
import { ImageService } from '../image.service';
import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { AppRouteNamesService, CustomSpotSearchType } from '../helpers/app-route-names.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
var metaLog = new Logger('Search-Spots');
var MetaService = /** @class */ (function () {
    function MetaService(title, meta, i18NService, propertyHelperService, commonService, imgService, route, appRouteNames, http) {
        this.title = title;
        this.meta = meta;
        this.i18NService = i18NService;
        this.propertyHelperService = propertyHelperService;
        this.commonService = commonService;
        this.imgService = imgService;
        this.route = route;
        this.appRouteNames = appRouteNames;
        this.http = http;
        this.metatagsInfoSearchAPI = "/api/metatags/spotsearch";
        this.metatagsOnlyhAPI = "/api/metatags/prerender/sent";
    }
    MetaService.prototype.setDynamicTags = function (tags, title) {
        var _this = this;
        this.title.setTitle(title || this.title.getTitle());
        this.meta.updateTag({ lang: this.getLangContent() });
        document.querySelector('html').setAttribute('lang', this.getLangContent());
        tags.forEach(function (t) {
            if (t.name) {
                if (t.name.includes('og:')) {
                    _this.meta.removeTag("property='" + t.name + "'");
                    _this.meta.updateTag({ property: t.name, content: t.content });
                }
                else {
                    _this.meta.removeTag("name='" + t.name + "'");
                    _this.meta.updateTag({ name: t.name, content: t.content });
                }
            }
        });
        // console.log('Meta Tags ', tags);
    };
    MetaService.prototype.setNonFoundTags = function () {
        this.meta.addTags([{ name: 'prerender-status-code', content: '404' }]);
    };
    MetaService.prototype.getMetaTagsFrom = function (title, description, keywords) {
        var tags = [
            // general
            { name: 'name', content: encodeURIComponent(title) },
            { name: 'lang', content: this.getLangContent() },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(window.location.href) },
            { name: 'og:locale', content: [this.i18NService.getCurrentLocale()] },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:site_url', content: "" + encodeURI(window.location.href) },
            { name: 'twitter:description', content: [description] },
            // Title
            { name: 'og:title', content: [title] },
            { name: 'title', content: [title] },
            { name: 'twitter:title', content: [title] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(title)
            },
            // Description
            { name: 'og:description', content: [description] },
            { name: 'description', content: [description] }
        ];
        if (keywords) {
            tags.push({ name: 'keywords', content: [keywords] });
        }
        return tags;
    };
    MetaService.prototype.setCompanyMetaTags = function (company, agentsDTO, spotBuildingNames) {
        var detailsMetaTags = this.buildCompanyMetatag(company, agentsDTO, spotBuildingNames);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var descText = company.biography ? this.removeHTMLCode(this.i18NService.getTranslation(company.biography)) : '';
        var locale = this.i18NService.getCurrentLocale();
        var companyTitle = this.i18NService.getTranslation(company.name);
        var fullDetailsURL = window.location.href;
        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'subject', content: detailsMetaTags.subject },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_ncompanyTitleTagame', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [companyTitle] },
            { name: 'og:email', content: 'spot@siila.com.mx' },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(company.mainImageId))
                    : null
            },
            {
                name: 'image',
                content: company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(company.mainImageId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [companyTitle] },
            {
                name: 'twitter:image',
                content: company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(company.mainImageId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    };
    MetaService.prototype.setCompanyMetaTagsLight = function (company) {
        var detailsMetaTags = this.buildCompanyMetatagLight(company);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var descText = company.biography ? this.removeHTMLCode(this.i18NService.getTranslation(company.biography)) : '';
        var locale = this.i18NService.getCurrentLocale();
        var companyTitle = this.i18NService.getTranslation(company.companyName);
        var fullDetailsURL = window.location.href;
        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'subject', content: detailsMetaTags.subject },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_ncompanyTitleTagame', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [companyTitle] },
            { name: 'og:email', content: 'spot@siila.com.mx' },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(company.mainImageId))
                    : null
            },
            {
                name: 'image',
                content: company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(company.mainImageId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [companyTitle] },
            {
                name: 'twitter:image',
                content: company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(company.mainImageId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    };
    MetaService.prototype.setBrokerMetaTags = function (brokerDTO, brokerBIO, spotBuildingNames) {
        var detailsMetaTags = this.buildBrokerMetatag(brokerDTO, spotBuildingNames);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var descText = brokerBIO ? this.removeHTMLCode(this.i18NService.getTranslation(brokerBIO)) : '';
        var locale = this.i18NService.getCurrentLocale();
        var brokerTitle = this.i18NService.getTranslation(brokerDTO.firstNameAndLastName);
        var fullDetailsURL = window.location.href;
        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: detailsMetaTags.subjectTag },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [brokerTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(brokerDTO.photoId))
                    : null
            },
            {
                name: 'image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(brokerDTO.photoId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [brokerTitle] },
            {
                name: 'twitter:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(brokerDTO.photoId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    };
    MetaService.prototype.setBrokerMetaTagsLight = function (brokerDTO) {
        var detailsMetaTags = this.buildBrokerMetatagLight(brokerDTO);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var descText = brokerDTO.biography
            ? this.removeHTMLCode(this.i18NService.getTranslation(brokerDTO.biography))
            : '';
        var locale = this.i18NService.getCurrentLocale();
        var brokerTitle = this.i18NService.getTranslation(brokerDTO.brokerName);
        var fullDetailsURL = window.location.href;
        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: detailsMetaTags.subjectTag },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [brokerTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(brokerDTO.photoId))
                    : null
            },
            {
                name: 'image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(brokerDTO.photoId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [brokerTitle] },
            {
                name: 'twitter:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(brokerDTO.photoId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    };
    MetaService.prototype.buildBrokerMetatag = function (broker, spotBuildingNames) {
        var metaTagTitle = '';
        var brokerTitleTag = broker.firstNameAndLastName;
        metaTagTitle = brokerTitleTag + " | SiiLA SPOT";
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var subjectTag = '';
        var keywordsMetaTags = [];
        keywordsMetaTags.push(broker.companyName);
        keywordsMetaTags.push(brokerTitleTag);
        if (spotBuildingNames) {
            spotBuildingNames.forEach(function (item, index) {
                keywordsMetaTags.push(item);
            });
        }
        var leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        var saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        keywordsMetaTags.push(broker.userEmail);
        subjectTag = brokerTitleTag + " | " + broker.companyName + " | SiiLA SPOT";
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, subjectTag: subjectTag };
    };
    MetaService.prototype.buildBrokerMetatagLight = function (broker) {
        var metaTagTitle = '';
        var brokerTitleTag = broker.brokerName;
        metaTagTitle = brokerTitleTag + " | SiiLA SPOT";
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var subjectTag = '';
        var keywordsMetaTags = [];
        keywordsMetaTags.push(broker.companyName);
        keywordsMetaTags.push(brokerTitleTag);
        if (broker.spotBuildingNames) {
            broker.spotBuildingNames.forEach(function (item, index) {
                keywordsMetaTags.push(item);
            });
        }
        var leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        var saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        keywordsMetaTags.push(broker.userEmail);
        subjectTag = brokerTitleTag + " | " + broker.companyName + " | SiiLA SPOT";
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, subjectTag: subjectTag };
    };
    MetaService.prototype.buildCompanyMetatag = function (company, agentsDTO, spotBuildingNames) {
        var metaTagTitle = '';
        var companyTitleTag = company.name;
        var subject = companyTitleTag + " | SiiLA SPOT";
        metaTagTitle = subject;
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var companyName = '';
        var keywordsMetaTags = [];
        var leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        var saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(companyTitleTag);
        if (spotBuildingNames) {
            spotBuildingNames.forEach(function (item, index) {
                keywordsMetaTags.push(item);
            });
        }
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        if (agentsDTO) {
            agentsDTO.forEach(function (item, index) {
                keywordsMetaTags.push(item.firstNameAndLastName);
            });
        }
        if (company.websiteURL) {
            keywordsMetaTags.push(company.websiteURL);
        }
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, subject: subject };
    };
    MetaService.prototype.buildCompanyMetatagLight = function (company) {
        var metaTagTitle = '';
        var companyTitleTag = company.companyName;
        var subject = companyTitleTag + " | SiiLA SPOT";
        metaTagTitle = subject;
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var companyName = '';
        var keywordsMetaTags = [];
        var leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        var saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(companyTitleTag);
        if (company.spotBuildingNames) {
            company.spotBuildingNames.forEach(function (item, index) {
                keywordsMetaTags.push(item);
            });
        }
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        if (company.agentNames) {
            company.agentNames.forEach(function (item, index) {
                keywordsMetaTags.push(item);
            });
        }
        if (company.website) {
            keywordsMetaTags.push(company.website);
        }
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, subject: subject };
    };
    MetaService.prototype.buildSpotBuildingMetatag = function (listing, locale) {
        var metaTagTitle = '';
        var metaTagSubject = '';
        // Listing Type
        var listingTypeTag = this.i18NService.get(this.propertyHelperService.getSpotBuildingTypeLongTranslation(listing.type));
        // Property Type
        var propertyTypeTag = '';
        var propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
        propertyTypeTag = this.i18NService.get(propertyTypeKey);
        var propertyTypeTitleTag = propertyTypeTag;
        // Building Name
        var buildingName = this.i18NService.getTranslation(listing.propertyName);
        var neighborhood = listing.neighborhood;
        var cityName = listing.building.city ? this.i18NService.getTranslation(listing.building.city.name) : undefined;
        var stateName = listing.building.city
            ? this.i18NService.getTranslation(listing.building.city.countryState.name)
            : undefined;
        var address = this.i18NService.getTranslation(listing.building.address);
        var company = listing.offeredByCompany.name;
        var subMarket = listing.building.subMarket ? listing.building.subMarket.name : '';
        //metaTagTitle = 'Logo SiiLA SPOT';
        metaTagTitle = buildingName + " | " + listingTypeTag + " | " + propertyTypeTitleTag + " | SiiLA SPOT";
        metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
        if (subMarket) {
            metaTagSubject += ', ' + subMarket;
        }
        metaTagSubject += " " + listingTypeTag;
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var companyName = '';
        var keywordsMetaTags = [];
        keywordsMetaTags.push(buildingName);
        keywordsMetaTags.push(propertyTypeTag);
        keywordsMetaTags.push(listingTypeTag);
        keywordsMetaTags.push(stateName);
        keywordsMetaTags.push(cityName);
        keywordsMetaTags.push(subMarket);
        keywordsMetaTags.push(address);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, metaTagSubject: metaTagSubject };
    };
    MetaService.prototype.getLangContent = function () {
        return this.i18NService.getCurrentLanguage() == 'en' ? 'en' : 'es';
    };
    MetaService.prototype.findMetatagsFrom = function (searchUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.metatagsInfoSearchAPI + searchUrl).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MetaService.prototype.onlyMetatags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.metatagsOnlyhAPI).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MetaService.prototype.setHomeMetatags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fullDetailsURL, locale, detailsMetaTags, fullTitleText, keywordsMetaTag, subject, descText, tags;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullDetailsURL = window.location.href;
                        locale = this.i18NService.getCurrentLocale();
                        return [4 /*yield*/, this.buildHomeMetatag(this.route.url, locale)];
                    case 1:
                        detailsMetaTags = _a.sent();
                        fullTitleText = detailsMetaTags.metaTagTitle;
                        keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
                        subject = detailsMetaTags.metaTagSubject;
                        descText = "Mexico's largest and most comprehensive database of available industrial, office and coworking spaces.";
                        if (locale !== 'en-US') {
                            descText =
                                'Base de datos más extensa y detallada de México para espacios disponibles industriales, de oficina y coworking.';
                        }
                        descText = detailsMetaTags.metaDescription ? detailsMetaTags.metaDescription : descText;
                        /*
                        metaLog.debug(
                          `Search Meta Tags: ${detailsMetaTags.metaTagTitle} - ${detailsMetaTags.metaTagSubject} - ${descText}`
                        );
                        */
                        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
                        tags = [
                            //change to be same as title
                            { name: 'lang', content: this.getLangContent() },
                            { name: 'name', content: encodeURIComponent(fullTitleText) },
                            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
                            { name: 'og:locale', content: [locale] },
                            { name: 'keywords', content: keywordsMetaTag },
                            //{ name: 'title', content: [fullTitleText] },
                            { name: 'subject', content: subject },
                            {
                                name: 'mobile-web-app-title',
                                content: encodeURIComponent(fullTitleText)
                            },
                            { name: 'description', content: [descText] },
                            { name: 'author', content: 'SiiLA' },
                            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
                            { name: 'og:site_name', content: fullTitleText },
                            { name: 'og:email', content: 'spot@siila.com.mx' },
                            { name: 'og:type', content: 'article' },
                            { name: 'og:title', content: [fullTitleText] },
                            { name: 'og:description', property: 'og:description', content: descText },
                            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
                            { name: 'twitter:card', content: "summary_large_image" },
                            { name: 'twitter:title', content: [fullTitleText] },
                            { name: 'twitter:description', content: descText },
                            {
                                name: 'og:image',
                                property: 'og:image',
                                content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
                            },
                            {
                                name: 'image',
                                content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
                            },
                            { name: 'twitter:card', content: "summary_large_image" },
                            { name: 'twitter:title', content: [fullTitleText] },
                            {
                                name: 'twitter:image',
                                content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
                            }
                        ];
                        this.setDynamicTags(tags, fullTitleText);
                        return [2 /*return*/];
                }
            });
        });
    };
    MetaService.prototype.buildHomeMetatag = function (url, locale) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var customSearchType, metaTagTitle, metaTagSubject, keywordsMetaTag, lang, metatagSearchAPI, response, metaDescription, spLocale;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        customSearchType = this.appRouteNames.findCustomSearchType(url);
                        metaTagTitle = null;
                        metaTagSubject = null;
                        keywordsMetaTag = null;
                        lang = locale == 'en-US' ? 'en' : 'es';
                        if (!customSearchType) return [3 /*break*/, 2];
                        metatagSearchAPI = this.i18NService.removeLanguageFromURL(url);
                        metatagSearchAPI = metatagSearchAPI.replace("/" + this.appRouteNames.getSearchRouteName(), '');
                        if (customSearchType == CustomSpotSearchType.STATE) {
                            metatagSearchAPI += '/default';
                        }
                        return [4 /*yield*/, this.findMetatagsFrom(metatagSearchAPI + "?lang=" + lang + "&customSearchType=" + customSearchType)];
                    case 1:
                        response = _a.sent();
                        metaLog.debug("Contains Search Custom " + customSearchType + " - " + response);
                        metaTagTitle = response.title;
                        keywordsMetaTag = response.title;
                        metaTagSubject = response.title;
                        metaDescription = response.description;
                        return [2 /*return*/, { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, metaTagSubject: metaTagSubject, metaDescription: metaDescription }];
                    case 2:
                        metaTagTitle = 'SiiLA SPOT | Commercial Properties for Lease and Sale';
                        spLocale = locale !== 'en-US';
                        metaTagSubject = 'SiiLA SPOT | Commercial Properties for Lease and Sale';
                        keywordsMetaTag =
                            'coworking cdmx, coworking mty, coworking polanco, coworking, wework, regus, reforma, polanco, santa fe, insurgentes, SiiLA SPOT, lease, siila.com.mx, SiiLA, offices, last mile, last mile delivery, compra y venta de inmuebles, inmuebles industriales, naves industriales, centro de distribucion, cedis,  nave industrial, CTT, renta de nave, Cuautitlan, Naucalpan, nave industrial en venta';
                        if (locale !== 'en-US') {
                            metaTagTitle = 'SiiLA SPOT | Propiedades comerciales en renta y venta';
                            keywordsMetaTag =
                                'coworking cdmx, coworking mty, coworking polanco, coworking, wework, regus, reforma, polanco, santa fe, insurgentes, SiiLA SPOT, lease, siila.com.mx, SiiLA, offices, last mile, last mile delivery, compra y venta de inmuebles, inmuebles industriales, naves industriales, centro de distribucion, cedis,  nave industrial, CTT, renta de nave, Cuautitlan, Naucalpan, nave industrial en venta';
                            metaTagSubject = 'SiiLA SPOT | Propiedades comerciales en renta y venta';
                        }
                        return [2 /*return*/, { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, metaTagSubject: metaTagSubject }];
                }
            });
        });
    };
    MetaService.prototype.setSpotBuildingMetaTags = function (buildingDetail, spotPhotos) {
        var locale = this.i18NService.getCurrentLocale();
        var detailsMetaTags = this.buildSpotBuildingMetatag(buildingDetail, locale);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var subject = detailsMetaTags.metaTagSubject;
        var descText = buildingDetail.comments
            ? this.removeHTMLCode(this.i18NService.getTranslation(buildingDetail.comments))
            : '';
        var listingTitle = this.i18NService.getTranslation(buildingDetail.title);
        var fullDetailsURL = window.location.href;
        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: subject },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:email', content: 'spot@siila.com.mx' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [fullTitleText] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: spotPhotos && spotPhotos.length
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId))
                    : null
            },
            {
                name: 'image',
                content: spotPhotos && spotPhotos.length
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [fullTitleText] },
            {
                name: 'twitter:image',
                content: spotPhotos && spotPhotos.length
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    };
    MetaService.prototype.setSpotBuildingMetaTags2 = function (listingDetail, spotPhotos) {
        var locale = this.i18NService.getCurrentLocale();
        var detailsMetaTags = this.buildSpotListingMetatag(listingDetail, locale);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var subject = detailsMetaTags.metaTagSubject;
        var descText = listingDetail.comments
            ? this.removeHTMLCode(this.i18NService.getTranslation(listingDetail.comments))
            : '';
        var listingTitle = this.i18NService.getTranslation(listingDetail.title);
        var fullDetailsURL = window.location.href;
        this.addCanonicalTag("" + encodeURI(fullDetailsURL));
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: subject },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:email', content: 'spot@siila.com.br' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [fullTitleText] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: listingDetail.photoId > 0
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(listingDetail.photoId))
                    : null
            },
            {
                name: 'image',
                content: listingDetail.photoId > 0
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(listingDetail.photoId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [fullTitleText] },
            {
                name: 'twitter:image',
                content: listingDetail.photoId > 0
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(listingDetail.photoId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    };
    MetaService.prototype.buildSpotListingMetatag = function (listing, locale) {
        var metaTagTitle = '';
        var metaTagSubject = '';
        // Listing Type
        var listingTypeKey = listing.listingType === 'L'
            ? 'global.list-your-property.lease'
            : listing.listingType === 'S'
                ? 'global.list-your-property.sale'
                : listing.listingType === 'C'
                    ? 'global.list-your-property.coworking'
                    : '';
        var listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());
        // Property Type
        var propertyTypeTag = '';
        var propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.buildingTypeId);
        propertyTypeTag = this.i18NService.get(propertyTypeKey);
        var propertyTypeTitleTag = propertyTypeTag;
        // Building Name
        var buildingName = this.i18NService.getTranslation(listing.title);
        var neighborhood = listing.neighborhood;
        var cityName = listing.city ? this.i18NService.getTranslation(listing.city) : undefined;
        var stateName = listing.state ? this.i18NService.getTranslation(listing.state) : undefined;
        var address = this.i18NService.getTranslation(listing.address);
        var company = listing.companyName;
        metaTagTitle = buildingName + " | " + listingTypeTag + " | " + propertyTypeTitleTag + " | SiiLA SPOT";
        metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
        if (neighborhood) {
            metaTagSubject += ', ' + neighborhood;
        }
        metaTagSubject += ' - ' + company + ' - ' + listingTypeTag;
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var companyName = '';
        var keywordsMetaTags = [];
        keywordsMetaTags.push(buildingName);
        keywordsMetaTags.push(listingTypeTag);
        keywordsMetaTags.push(propertyTypeTag);
        keywordsMetaTags.push(cityName);
        keywordsMetaTags.push(stateName);
        keywordsMetaTags.push(neighborhood);
        keywordsMetaTags.push(address);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag, metaTagSubject: metaTagSubject };
    };
    MetaService.prototype.removeHTMLCode = function (htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    };
    MetaService.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    MetaService.prototype.addCanonicalTag = function (currentPath) {
        var path = currentPath;
        if (path == null || path === undefined) {
            return path;
        }
        //if (path.endsWith("/en")) {
        //  path = path.slice(0, path.length - 8);
        //}
        /* const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = path;
        document.head.appendChild(linkTag); */
        var existingCanonicalLink = document.querySelector("link[rel='canonical']");
        if (!existingCanonicalLink) {
            var linkTag = document.createElement('link');
            linkTag.setAttribute('rel', 'canonical');
            linkTag.href = path;
            document.head.appendChild(linkTag);
        }
        else {
            existingCanonicalLink.href = path;
        }
    };
    return MetaService;
}());
export { MetaService };
