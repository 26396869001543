import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, ElementRef, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingService, EventCheckerService, CreateListingService, ListingCarouselService } from '@app/core/listings';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as Fuse from 'fuse.js';
import { IonSelect, IonContent } from '@ionic/angular';
import { PROPERTY_TYPES } from '@app/models';
import { TRANSACTION_TYPES, ListingOfferType } from '@app/models/transaction-type.model';
import { I18nService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { SearchTagsService } from '@app/core/search-tags.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { RegisterService } from '@app/core/register.service';
import { ReplaySubject } from 'rxjs';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { SelectedFilterKey } from './filter-selected-keys';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { UserService, UserRoles } from '@app/core/helpers/user.service';
import { StatusHelperService } from '@app/core/helpers/status-helper.service';
import { ClassHelperService } from '@app/core/helpers/classes-helper.service';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { takeUntil } from 'rxjs/operators';
import { MetaService } from '@app/core/seo';
import { environment } from '@env/environment';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { ShellComponent } from '@app/shell/shell.component';
var ListingSearchComponent = /** @class */ (function () {
    function ListingSearchComponent(router, listingService, activatedRoute, location, i18nService, breakpointObserver, createListingService, commonService, searchTagService, registerService, _eventChecker, nearbyListingSvc, listingSearchService, userService, statusHelperService, classHelperService, popOverCtrlHelper, listingCarouselService, metaService, userActivityService, sellComponent) {
        var _this = this;
        this.router = router;
        this.listingService = listingService;
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.i18nService = i18nService;
        this.breakpointObserver = breakpointObserver;
        this.createListingService = createListingService;
        this.commonService = commonService;
        this.searchTagService = searchTagService;
        this.registerService = registerService;
        this._eventChecker = _eventChecker;
        this.nearbyListingSvc = nearbyListingSvc;
        this.listingSearchService = listingSearchService;
        this.userService = userService;
        this.statusHelperService = statusHelperService;
        this.classHelperService = classHelperService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.listingCarouselService = listingCarouselService;
        this.metaService = metaService;
        this.userActivityService = userActivityService;
        this.sellComponent = sellComponent;
        this.scrollToCarousel = new EventEmitter();
        this.displayScrollToTop = true;
        this.mapToggleIcons = {
            map: { imageUrl: 'assets/images/icons/google-map-225.png' },
            list: { imageUrl: 'assets/images/icons/list-view.png' }
        };
        this.propertyTypes = tslib_1.__spread(PROPERTY_TYPES);
        this.transactionTypes = tslib_1.__spread(TRANSACTION_TYPES);
        this.isMobile = false;
        this.breadcrumbNavOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.listingSearchDto = {};
        this.listings = [];
        this.listingMarkers = [];
        this.displayMap = false;
        this.selectOptions = { cssClass: 'top-search-btn' };
        this.mapOptions = {
            zoom: 15,
            singleMarker: false,
            ignoreZoom: false,
            geopositionControl: true
        };
        this.inputCity = '';
        this.optionSelected = false;
        this.cityOptions = [];
        this.searching = false;
        this.searchingMoreListings = false;
        this.currentPage = 1;
        this.totalListingsCount = 0;
        this.totalActiveListingsCount = 0;
        this.showMoreIcon = false;
        this.searchTagsLength = 0;
        this.noListingsFoundMessage = '';
        this.avoidSearchOnBuildingTypeChange = false;
        this.avoidSearchOnListingTypeChange = false;
        this.showUniquePropertyListing = false;
        this.isInfowindowClose = false;
        this.allMatchingListing = [];
        this.currentActiveInfoWindow = null;
        this.admin = false;
        this.searchAnalytics = true;
        this.hasGeoLocationError = false;
        this.loadingPage = false;
        this.isFavoritesPage = false;
        this.showAllFavoritesOnly = false;
        this.destroyed$ = new ReplaySubject(1);
        this.disabledExtendListingsBtn = true;
        this.contFilter = 0;
        this.searchByGeoLocation = function (position) {
            _this.hasGeoLocationError = false;
            _this.clientPosition = _this.nearbyListingSvc.getUserLocationCoordinate(position.coords);
            _this.nearByListingSearch(_this.clientPosition);
        };
        this.showGeoLocationError = function (error) {
            _this.hasGeoLocationError = true;
            _this.noListingsFoundMessage = _this.nearbyListingSvc.getNoResultMessage(0);
            _this.geoLocationErrorCode = _this.nearbyListingSvc.getGeoLocationErrorCode(error);
        };
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.screenWidth = window.innerWidth;
        this.outerScreenWidth = window.outerWidth;
        this.classLbl = this.i18nService.get('global.listing-card.class');
        this.searchModel = {
            keywordText: '',
            propertyType: '',
            listingType: '',
            stateId: null,
            cityId: null,
            propertySubTypes: null
        };
    }
    ListingSearchComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
        this.outerScreenWidth = event.target.outerWidth;
    };
    ListingSearchComponent.prototype.ngOnDestroy = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.destroyed$.next(true);
                this.destroyed$.complete();
                if (this.modalSubscribtion) {
                    this.modalSubscribtion.unsubscribe();
                }
                if (this.userGeoLocationSubscription) {
                    this.userGeoLocationSubscription.unsubscribe();
                }
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscribeToParamsChange();
        this.loadingPage = true;
        this.searchModalSubscription();
        this.localStorageKey = this.listingSearchService.findLocalStorageKeyFromSelectedFilters({
            myListingAnalytics: this.myListingAnalytics,
            myListingPage: this.myListingPage,
            favoritesPage: this.isFavoritesPage
        });
        this.initSpotNearMeSearch();
        this.clearSearchData();
        this.getSearchDTO();
        this.breakpointObserver.observe([Breakpoints.Handset, '(min-width: 700px)']).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        this.faSearch = faSearch;
        this.spotAdmin = this.userService.checkRole(UserRoles.ROLE_SPOT_ADMIN);
        this.listingAdmin = this.userService.checkRole(UserRoles.ROLE_LISTING_ADMIN);
        this.getAdvertisements();
        this.updateListingNow();
        this.loadingPage = false;
    };
    ListingSearchComponent.prototype.subscribeToParamsChange = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var from = params.from;
            if (from && from.includes('favorites')) {
                _this.isFavoritesPage = true;
                _this.listingSearchDto.onlyFavorites = true;
                _this.clearSpotNearMeSearch();
                if (/^(favorites)\d+$/g.test(from)) {
                    _this.showAllFavoritesOnly = true;
                }
            }
            else {
                _this.isFavoritesPage = false;
            }
            _this.date = new Date();
        });
    };
    ListingSearchComponent.prototype.searchModalSubscription = function () {
        var _this = this;
        if (!this.modalSubscribtion) {
            this.modalSubscribtion = this.listingService.currentModel.pipe(takeUntil(this.destroyed$)).subscribe(function (model) {
                var sm = model;
                if (sm === 'reload') {
                    _this.searchMyListings(_this.listingSearchDto);
                }
                else if (sm === 'destroy' && !_this.loadingPage) {
                    return;
                }
                else if (sm && !sm.updateModelFromListingDetail) {
                    if (sm.regularSearch && !_this.isComponentLoadedFromRegularSearch()) {
                        return;
                    }
                    if (!Object.keys(sm).length) {
                        return;
                    }
                    _this.searchModel = sm;
                    _this.getFilter();
                    _this.updateSearchDTOFromModal();
                }
                else if (sm && sm.updateModelFromListingDetail) {
                    _this.listingSearchDto = _this.listingSearchService.readSearchDTOFromStorage(SelectedFilterKey.REGULAR_SEARCH, {});
                    _this.listingService.propertySubTypesFromHomepage = _this.listingSearchDto.propertySubTypes;
                    _this.searchModel = sm;
                    _this.getFilter();
                    _this.searchModel.updateModelFromListingDetail = false;
                    _this.updateSearchModalFromSearchDTO();
                }
            });
        }
    };
    ListingSearchComponent.prototype.getFilter = function () {
        if (this.router.url.includes('filter=true') && this.contFilter == 0) {
            document.getElementById('filter').click();
            this.contFilter = 1;
        }
    };
    ListingSearchComponent.prototype.getCompanies = function () {
        var _this = this;
        this.registerService
            .getListingCompanies()
            .toPromise()
            .then(function (companies) {
            _this.allCompanies = companies;
            _this.setAdminView();
        });
    };
    ListingSearchComponent.prototype.getSearchDTO = function () {
        var _this = this;
        this.getListingSearchDto().then(function () {
            _this.checkBoxValues = _this.createListingService.initCheckboxFields();
            _this.updateSearchModalFromSearchDTO();
            _this.triggerSearchFilterChange();
            if (_this.isComponentLoadedFromRegularSearch()) {
                _this.getStates();
                if (_this.searchModel.stateId)
                    _this.getCities();
                _this.searchListings(_this.activatedRoute.snapshot.url.length <= 1);
                _this.setMetaTags();
            }
        });
    };
    ListingSearchComponent.prototype.getListingSearchDto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getCities()];
                    case 1:
                        _a.allCities = _b.sent();
                        if (!this.showAllFavoritesOnly) {
                            this.listingSearchDto = this.listingSearchService.readSearchDTOFromStorage(this.localStorageKey, this.listingSearchDto);
                        }
                        if (!!this.isComponentLoadedFromRegularSearch()) return [3 /*break*/, 2];
                        this.statusHelperService.getStatuses();
                        this.getCompanies();
                        this.searchListings(true);
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(!this.listingSearchDto ||
                            !this.listingSearchDto.listingLeaseOrSale ||
                            (this.listingSearchDto &&
                                this.listingSearchDto.listingLeaseOrSale &&
                                this.listingSearchDto.listingLeaseOrSale[0] !== ListingOfferType.Coworking))) return [3 /*break*/, 6];
                        if (!(!this.listingSearchDto || !this.listingSearchDto.listingLeaseOrSale || !this.listingSearchDto.buildingTypes)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.buildSearchDTOBasedOnURL()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        if (!(this.listingSearchDto &&
                            this.listingSearchDto.buildingTypes &&
                            this.listingSearchDto.buildingTypes[0] !== ListingOfferType.Coworking)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.buildSearchDTOBasedOnURL()];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.buildSearchDTOBasedOnURL = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchURL, params, buildingNameFilter_1, error_1, state;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchURL = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        params = this.activatedRoute.snapshot.params;
                        if (!(this.activatedRoute.snapshot.url.length > 1 &&
                            params &&
                            params.from !== 'spotsNearMe' &&
                            !this.isFavoritesPage)) return [3 /*break*/, 3];
                        searchURL = this.activatedRoute.snapshot.url.slice(1).join('/');
                        searchURL = this.i18nService.removeLanguageFromURL(searchURL);
                        if (!searchURL || searchURL.length == 0) {
                            return [2 /*return*/];
                        }
                        buildingNameFilter_1 = this.activatedRoute.snapshot.queryParamMap.get('building');
                        return [4 /*yield*/, this.listingService
                                .getListingsSearchDto(searchURL, this.activatedRoute.snapshot.queryParamMap.get('keyword'), buildingNameFilter_1)
                                .then(function (dtoFromURL) {
                                if (dtoFromURL) {
                                    _this.listingSearchDto.buildingName = buildingNameFilter_1;
                                    _this.listingSearchDto.buildingTypes = dtoFromURL.buildingTypes
                                        ? dtoFromURL.buildingTypes
                                        : _this.listingSearchDto.buildingTypes;
                                    _this.listingSearchDto.cityId = dtoFromURL.cityId ? dtoFromURL.cityId : _this.listingSearchDto.cityId;
                                    _this.listingSearchDto.listingLeaseOrSale = dtoFromURL.listingLeaseOrSale
                                        ? dtoFromURL.listingLeaseOrSale
                                        : _this.listingSearchDto.listingLeaseOrSale;
                                    _this.listingSearchDto.stateId = dtoFromURL.stateId ? dtoFromURL.stateId : _this.listingSearchDto.stateId;
                                    _this.listingSearchDto.keyword = dtoFromURL.keyword;
                                    _this.listingSearchDto.buildingId = dtoFromURL.buildingId;
                                }
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        state = {
                            badURL: environment.spotServerUrl + "/search/" + searchURL
                        };
                        console.error('Error search page', error_1, searchURL);
                        this.router.navigateByUrl('/not-found', { state: state });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.setAdminView = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.selectCompany = null;
                        this.selectUser = null;
                        if (!this.listingSearchDto) return [3 /*break*/, 2];
                        this.setInitialValuesForAdminUsers();
                        if (!this.listingSearchDto.companyId) return [3 /*break*/, 2];
                        this.selectCompany = this.listingService.getSelectedCompany(this.allCompanies, this.listingSearchDto.companyId)[0];
                        _a = this;
                        return [4 /*yield*/, this.getUsersByCompany(this.selectCompany.id)];
                    case 1:
                        _a.users = _b.sent();
                        if (this.listingSearchDto.offeredByUserId) {
                            this.selectUser = this.listingService.getSelectedAgent(this.users, this.listingSearchDto.offeredByUserId)[0];
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.updateSearchDTOFromModal = function () {
        if (this.listingSearchDto && this.searchModel) {
            this.listingSearchDto.buildingTypes = this.searchModel.propertyType ? [this.searchModel.propertyType] : null;
            this.listingSearchDto.listingLeaseOrSale = this.searchModel.listingType ? [this.searchModel.listingType] : null;
            this.listingSearchDto.stateId = this.searchModel.stateId ? this.searchModel.stateId : null;
            this.listingSearchDto.cityId = this.searchModel.cityId ? this.searchModel.cityId : null;
            this.listingSearchDto.keyword = this.searchModel.keywordText ? this.searchModel.keywordText : null;
            if (this.searchModel.propertySubTypes) {
                this.listingSearchDto.propertySubTypes = this.searchModel.propertySubTypes;
                this.listingSearchDto.propertySubtypeIds = this.searchModel.propertySubTypes.map(function (t) { return t.id; });
            }
            else {
                this.listingSearchDto.propertySubtypeIds = null;
            }
        }
    };
    ListingSearchComponent.prototype.updateSearchModalFromSearchDTO = function () {
        //Clear out few things before initializing city, state, propType and listingType from carousel urls
        this.clearSearchModel();
        if (this.listingSearchDto) {
            if (this.listingSearchDto && this.listingSearchDto.buildingTypes) {
                this.avoidSearchOnBuildingTypeChange = true;
                this.searchModel.propertyType = this.listingSearchDto.buildingTypes[0];
            }
            if (this.listingSearchDto && this.listingSearchDto.listingLeaseOrSale) {
                this.avoidSearchOnListingTypeChange = true;
                this.searchModel.listingType = this.listingSearchDto.listingLeaseOrSale[0]
                    ? this.listingSearchDto.listingLeaseOrSale[0].toString()
                    : null;
            }
            if (this.listingSearchDto.stateId)
                this.searchModel.stateId = this.listingSearchDto.stateId;
            if (this.listingSearchDto.cityId)
                this.searchModel.cityId = this.listingSearchDto.cityId;
            if (this.listingSearchDto.keyword)
                this.searchModel.keywordText = this.listingSearchDto.keyword;
            this.searchModel.propertySubTypes = this.listingSearchDto.propertySubTypes;
            this.searchModel.coworkingPositions = this.listingSearchDto.coworkingPositions;
            this.searchModel.coworkingType = this.listingSearchDto.coworkingType;
            this.setCoworkingSelectedBasedOnSearchModel();
        }
    };
    ListingSearchComponent.prototype.searchMyListings = function (dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dto.myListingPage = true;
                        this.disabledExtendListingsBtn = true;
                        return [4 /*yield*/, this.listingService.searchListingIds(this.listingSearchDto, 1).then(function (listingIds) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.totalActiveListingsCount = listingIds && listingIds.headers ? listingIds.headers.get('X-Total-Active') : 0;
                                            this.totalListingsCount = listingIds.body.length;
                                            if (!(!this.totalListingsCount || this.totalListingsCount == 0)) return [3 /*break*/, 1];
                                            this.noSearchResults();
                                            return [3 /*break*/, 5];
                                        case 1:
                                            if (!this.myListingAnalytics) return [3 /*break*/, 3];
                                            return [4 /*yield*/, this.getAnalyticsData(listingIds.body)];
                                        case 2:
                                            _a.sent();
                                            return [2 /*return*/];
                                        case 3: return [4 /*yield*/, this.getListingsByPage(1, 20, listingIds.body)];
                                        case 4:
                                            _a.sent();
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingSearchComponent.prototype.getAnalyticsData = function (listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getListingAnalytics(listingIds)];
                    case 1:
                        _a.analyticsData = _b.sent();
                        this.searchAnalytics = false;
                        if (!this.analyticsData) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getListingsByPage(1, 20, listingIds)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.getListingsByPage = function (page, pageSize, listingIds, concatNewResult) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingService
                            .getListingsByPage(page, pageSize, listingIds, this.listingSearchDto.coworkingPositions, this.listingSearchDto.coworkingType, this.currentSearchTracking && this.currentSearchTracking.searchId ? this.currentSearchTracking.searchId : null, this.myListingAnalytics ? true : false)
                            .then(function (result) {
                            _this.showMoreIcon = _this.totalListingsCount - _this.currentPage * pageSize > 0;
                            if (concatNewResult) {
                                var newListings = result;
                                if (newListings && newListings.length) {
                                    _this.listings = _this.listings.concat(newListings);
                                }
                            }
                            else {
                                _this.listings = result;
                            }
                            _this.allListingIds = listingIds;
                            _this.updateTags();
                            _this.isInValidToRenewAndExtendListings();
                            _this.searching = false;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.isInValidToRenewAndExtendListings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expiredOrActiveFilters;
            return tslib_1.__generator(this, function (_a) {
                this.disabledExtendListingsBtn = true;
                if (this.myListingPage && this.allListingIds && this.listingSearchDto && this.listingSearchDto.listingStatuses) {
                    expiredOrActiveFilters = this.listingSearchDto.listingStatuses.filter(function (status) { return status == '4' || status == '5'; });
                    this.disabledExtendListingsBtn = !(expiredOrActiveFilters.length === this.listingSearchDto.listingStatuses.length);
                }
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.searchListingsChangeListener = function () {
        this.updateSearchDTOFromModal();
        this.searchListings(true);
    };
    ListingSearchComponent.prototype.searchListings = function (resetUrl, clientPosition) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isSpotNearMeSearch;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (localStorage.getItem('sell')) {
                            localStorage.removeItem('sell');
                        }
                        else {
                            this.sellComponent.openLoginDialogFirstLoad(true);
                        }
                        this.noListingsFoundMessage = '';
                        this.searching = true;
                        this.showUniquePropertyListing = false;
                        this.currentActiveInfoWindow = null;
                        this.currentPage = 1;
                        this.allMatchingListing = [];
                        isSpotNearMeSearch = this.isSpotNearMeSearch;
                        if (resetUrl && this.isComponentLoadedFromRegularSearch()) {
                            this.resetUrl();
                        }
                        this.commonService.deleteNullAttributesFrom(this.listingSearchDto);
                        this.listingSearchService.saveSearchDtoOnStorage(this.localStorageKey, this.listingSearchDto);
                        this.triggerSearchFilterChange();
                        this.isSpotNearMeSearch = isSpotNearMeSearch;
                        return [4 /*yield*/, this.updateTags()];
                    case 1:
                        _a.sent();
                        this.setMetaTags();
                        // tracking listings Search
                        return [4 /*yield*/, this.trackingListingsSearch()];
                    case 2:
                        // tracking listings Search
                        _a.sent();
                        return [4 /*yield*/, this.findAllSearchInfoToBeDisplayed().then(function (r) {
                                if (!_this.isSpotNearMeSearch) {
                                    _this.searching = false;
                                }
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.findAllSearchInfoToBeDisplayed = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isComponentLoadedFromRegularSearch()) return [3 /*break*/, 2];
                        this.listingSearchDto = this.removeSearchAttr(this.listingSearchDto);
                        this.hasGeoLocationError = false;
                        return [4 /*yield*/, this.searchMyListings(this.listingSearchDto)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 2:
                        if (!this.isSpotNearMeSearch) return [3 /*break*/, 3];
                        this.getUserCurrentGeoLocation();
                        return [3 /*break*/, 7];
                    case 3:
                        if (!this.listingSearchDto.onlyFavorites) return [3 /*break*/, 5];
                        this.hasGeoLocationError = false;
                        this.listingSearchDto = this.removeSearchAttr(this.listingSearchDto);
                        return [4 /*yield*/, this.regularListingSearch()];
                    case 4:
                        _a.sent();
                        this.getAllListing();
                        return [3 /*break*/, 7];
                    case 5:
                        this.hasGeoLocationError = false;
                        this.listingSearchDto = this.removeSearchAttr(this.listingSearchDto);
                        return [4 /*yield*/, this.regularListingSearch()];
                    case 6:
                        _a.sent();
                        this.getAllListing();
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.trackingListingsSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchTags, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.currentTags) return [3 /*break*/, 2];
                        searchTags = JSON.parse(JSON.stringify(this.currentTags));
                        searchTags.map(function (tag) {
                            tag.name = _this.i18nService.getTranslationInTargetLanguage(tag.name, 'es');
                            return tag;
                        });
                        _a = this;
                        return [4 /*yield*/, this.userActivityService.trackListingsSearch(this.listingSearchDto, searchTags)];
                    case 1:
                        _a.currentSearchTracking = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.triggerSearchFilterChange = function () {
        this.updateSearchModalFromSearchDTO();
        if (this.searchModel) {
            this.searchModel.regularSearch = this.isComponentLoadedFromRegularSearch();
            var page = this.myListingAnalytics ? 'ANALYTICS' : this.myListingPage ? 'LISTING_PAGE' : 'REGULAR';
            this.searchModel.searchPage = page;
        }
        this.listingService.updateModel(this.searchModel);
    };
    ListingSearchComponent.prototype.updateTags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.searchTagService.updateSearchTags(this.listingSearchDto, this.isSpotNearMeSearch)];
                    case 1:
                        _a.currentTags = _b.sent();
                        this.statusHelperService.updateSelectedStatusesBasedOnDTO(this.listingSearchDto);
                        this.classHelperService.updateSelectedClassesBasedOnDTO(this.listingSearchDto);
                        return [2 /*return*/, this.currentTags];
                }
            });
        });
    };
    ListingSearchComponent.prototype.getUsersByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getUsersByCompany(companyId).toPromise()];
            });
        });
    };
    ListingSearchComponent.prototype.removeMyListingTag = function (item) {
        this.selectCompany = null;
        this.selectUser = null;
        this.listingSearchDto.companyId = null;
        this.listingSearchDto.offeredByUserId = null;
        this.searchListings(true);
    };
    ListingSearchComponent.prototype.removeMyUserTag = function (item) {
        this.selectUser = null;
        this.listingSearchDto.offeredByUserId = null;
        this.searchListings(true);
    };
    ListingSearchComponent.prototype.removeMyStatusTag = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.statusRemoved = true;
                        this.statusHelperService.removeStatus(status);
                        _a = this.listingSearchDto;
                        return [4 /*yield*/, this.statusHelperService.getStatusIds()];
                    case 1:
                        _a.listingStatuses = _b.sent();
                        return [4 /*yield*/, this.searchListings(true)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.statusChange()];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.statusChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.statusRemoved) {
                            if (!this.listingSearchDto.listingStatuses.length) {
                                this.statusHelperService.selectStatus = [];
                            }
                            this.statusRemoved = false;
                            return [2 /*return*/];
                        }
                        _a = this.listingSearchDto;
                        return [4 /*yield*/, this.statusHelperService.getStatusIds()];
                    case 1:
                        _a.listingStatuses = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.removeMyClassTag = function (listingClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.classRemoved = true;
                        this.classHelperService.removeClass(listingClass);
                        _a = this.listingSearchDto;
                        return [4 /*yield*/, this.classHelperService.getClassIds()];
                    case 1:
                        _a.listingClasses = _b.sent();
                        return [4 /*yield*/, this.searchListings(true)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.classChange()];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.classChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.classRemoved) {
                            if (!this.listingSearchDto.listingClasses.length) {
                                this.classHelperService.selectClass = [];
                            }
                            this.classRemoved = false;
                            return [2 /*return*/];
                        }
                        _a = this.listingSearchDto;
                        return [4 /*yield*/, this.classHelperService.getClassIds()];
                    case 1:
                        _a.listingClasses = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Observable functions for handling update events
    ListingSearchComponent.prototype.updateListingNow = function () {
        var _this = this;
        this._eventChecker.updateListing$.subscribe(function (listing) {
            _this.isInValidToRenewAndExtendListings();
            if (listing) {
                var updatedListing = _this.listings.find(function (currListing) { return listing.id === currListing.id; });
                var favoriteChanged = !!updatedListing && updatedListing.favorite !== listing.favorite;
                var isFavoriteFiltered = _this.listingSearchDto.onlyFavorites;
                if (listing === 'refresh' || (isFavoriteFiltered && favoriteChanged)) {
                    if (!_this.loadingPage) {
                        _this.searchListings(true, null);
                    }
                }
                else if (listing === 'no-refresh') {
                    console.log('No refresh');
                }
                else {
                    if (_this.listings) {
                        _this.listings = _this.listings.map(function (currListing) {
                            if (listing.id === currListing.id) {
                                return listing;
                            }
                            return currListing;
                        });
                    }
                    if (_this.allMatchingListing) {
                        _this.allMatchingListing = _this.allMatchingListing.map(function (matchingListing) {
                            if (listing.id === matchingListing.id) {
                                return listing;
                            }
                            return matchingListing;
                        });
                    }
                }
            }
        });
        this._eventChecker.removeListing$.subscribe(function (id) {
            if (_this.listings && id) {
                var index = _this.listings.findIndex(function (v) {
                    return v.id === id;
                });
                _this.listings.splice(index, 1);
            }
        });
        this._eventChecker.paUpdate$.subscribe(function (listing) {
            if (_this.listings && listing) {
                var index = _this.listings.findIndex(function (v) {
                    return v.id === listing.id;
                });
                _this.listings[index] = listing;
            }
        });
    };
    ListingSearchComponent.prototype.clearCompany = function () {
        this.listingSearchDto.listingStatuses = null;
        this.selectCompany = null;
        this.selectUser = null;
        if (this.spotAdmin) {
            this.listingSearchDto.offeredByUserId = null;
            this.listingSearchDto.companyId = null;
        }
        else if (this.listingAdmin) {
            this.listingSearchDto.offeredByUserId = null;
        }
    };
    ListingSearchComponent.prototype.searchByGeoposition = function (clientMarker) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clientCoordinates;
            return tslib_1.__generator(this, function (_a) {
                if (clientMarker.position && clientMarker.map) {
                    clientMarker.clientCoordinates = true;
                    clientCoordinates = {
                        lat: -23.548322,
                        lng: -46.631155,
                        clientCoordinates: true
                    };
                    this.refreshSearchData(clientCoordinates);
                }
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.refreshSearchData = function (clientCoordinates) {
        this.searchListings(clientCoordinates);
    };
    ListingSearchComponent.prototype.openCoworkingPopover = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.popOverCtrlHelper.openCoworkingPopover(ev, this.listingSearchDto, function (popoverData) {
                    _this.listingSearchDto = popoverData;
                    _this.searchModel.coworkingPositions = popoverData.coworkingPositions;
                    _this.searchModel.coworkingType = popoverData.coworkingType;
                    _this.listingService.coworkingFilterFromHomepage = {};
                    _this.listingService.coworkingFilterFromHomepage.coworkingPositions = popoverData.coworkingPositions;
                    _this.listingService.coworkingFilterFromHomepage.coworkingType = popoverData.coworkingType;
                    _this.searchListings(true);
                });
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.haveSubTypesChanged = function (oldPropertyType, newPropertyType) {
        if (oldPropertyType == newPropertyType) {
            var newSubTypes = this.listingSearchDto.propertySubTypes;
            var oldSubTypes = this.searchModel.propertySubTypes;
            if (!newSubTypes && !oldSubTypes) {
                return false;
            }
            return JSON.stringify(newSubTypes) !== JSON.stringify(oldSubTypes);
        }
        return false;
    };
    ListingSearchComponent.prototype.setCoworkingSelectedBasedOnSearchModel = function () {
        this.coworkingSelected = false;
        if (this.searchModel) {
            if (this.searchModel.listingType === ListingOfferType.Coworking) {
                this.coworkingSelected = true;
            }
            if (!this.searchModel.propertySubTypes || this.searchModel.propertySubTypes.length == 0) {
                this.clearPropertySubtypes();
            }
            if (!this.coworkingSelected) {
                this.clearCoworkingValues();
            }
            if (this.coworkingSelected && !this.searchModel.coworkingType) {
                this.searchModel.coworkingType = 'shared';
                this.searchModel.coworkingPositions = this.searchModel.coworkingPositions
                    ? this.searchModel.coworkingPositions
                    : 1;
            }
        }
    };
    ListingSearchComponent.prototype.toggleMap = function (override) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.displayMap = !this.displayMap || override;
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.openTypePopover = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    ListingSearchComponent.prototype.openProptypePopover = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    ListingSearchComponent.prototype.checkPropertyType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.avoidSearchOnBuildingTypeChange)
                    this.searchListings(true);
                this.avoidSearchOnBuildingTypeChange = false;
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.navigateToSearch = function (event) {
        event.data = this.searchModel;
        this.scrollToCarousel.emit(event);
    };
    ListingSearchComponent.prototype.clearSearchData = function () {
        this.clearSearchModel();
        this.clearPropertySubtypes();
        this.clearCoworkingValues();
        this.clearListingSearchDTO();
        this.updateTags();
    };
    ListingSearchComponent.prototype.clearSearchModel = function () {
        this.searchModel = {
            cityId: null,
            stateId: null,
            propertySubTypeIds: null,
            propertyType: null,
            propertySubTypes: null,
            listingType: null,
            keywordText: null
        };
    };
    ListingSearchComponent.prototype.clearSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.avoidSearchByChangedModelAfterClearSearch();
                this.clearSearchModel();
                this.clearPropertySubtypes();
                this.clearCoworkingValues();
                this.clearListingSearchDTO();
                this.clearSpotNearMeSearch();
                this.clearFavoritesSearchSearch();
                this.searchTagService.clearSearchTags();
                this.triggerSearchFilterChange();
                this.searchListings(true);
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.clearSpotNearMeSearch = function () {
        this.isSpotNearMeSearch = false;
    };
    ListingSearchComponent.prototype.clearFavoritesSearchSearch = function () {
        this.showAllFavoritesOnly = false;
        this.listingSearchDto.onlyFavorites = false;
    };
    ListingSearchComponent.prototype.avoidSearchByChangedModelAfterClearSearch = function () {
        this.avoidSearchOnListingTypeChange = true;
        this.avoidSearchOnBuildingTypeChange = true;
    };
    ListingSearchComponent.prototype.clearListingSearchDTO = function () {
        this.allMatchingListing = [];
        this.listingSearchDto = this.searchTagService.clearSearchTagsFromDTO(this.listingSearchDto);
        if (this.listingSearchDto) {
            this.listingSearchDto.buildingTypes = null;
            this.listingSearchDto.listingLeaseOrSale = null;
            this.listingSearchDto.stateId = null;
            this.listingSearchDto.cityId = null;
            this.listingSearchDto.keyword = null;
        }
    };
    ListingSearchComponent.prototype.clearCoworkingValues = function () {
        this.listingService.coworkingFilterFromHomepage = {};
        this.searchModel.coworkingPositions = null;
        this.searchModel.coworkingType = null;
        this.listingSearchDto.coworkingPositions = null;
        this.listingSearchDto.coworkingType = null;
    };
    ListingSearchComponent.prototype.clearPropertySubtypes = function () {
        this.listingService.propertySubTypesFromHomepage = null;
        this.searchModel.propertySubTypes = null;
        this.listingSearchDto.propertySubTypes = null;
        this.listingSearchDto.propertySubtypeIds = null;
    };
    ListingSearchComponent.prototype.regularListingSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingService.searchListingIds(this.listingSearchDto, 1).then(function (listingIds) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.totalActiveListingsCount = listingIds && listingIds.headers ? listingIds.headers.get('X-Total-Active') : 0;
                                        this.totalListingsCount = listingIds.body.length;
                                        if (!(!this.totalListingsCount || this.totalListingsCount === 0)) return [3 /*break*/, 1];
                                        this.noSearchResults();
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.getListingsByPage(1, 10, listingIds.body)];
                                    case 2:
                                        _a.sent();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingSearchComponent.prototype.noSearchResults = function () {
        this.noListingsFoundMessage = this.i18nService.get('global.search.noListingsFound');
        this.listings = [];
        this.showMoreIcon = this.totalListingsCount - this.currentPage * 20 > 0;
        this.allListingIds = null;
        this.updateTags();
    };
    ListingSearchComponent.prototype.resetUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentUrlObject, response, lang, newUrl, currentUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSearchDTOFromModal();
                        currentUrlObject = this.activatedRoute.snapshot.url;
                        if (!(this.listingSearchDto.buildingTypes ||
                            this.listingSearchDto.listingLeaseOrSale ||
                            this.listingSearchDto.stateId ||
                            this.listingSearchDto.cityId ||
                            this.listingSearchDto.keyword ||
                            (currentUrlObject.length > 1 && this.isSpotNearMeSearch))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.listingService.getUrlFromListingsSearchDto(this.listingSearchDto)];
                    case 1:
                        response = _a.sent();
                        lang = this.i18nService.getCurrentLanguage();
                        response.url = JSON.parse(response.url);
                        newUrl = "" + response.url[lang] + (response.keyword ? '?keyword=' + response.keyword : '');
                        currentUrl = currentUrlObject.slice(1).join('/');
                        if ("/" + currentUrl !== newUrl) {
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                this.location.go(this.i18nService.addCurrentLanguageToPath("/search" + newUrl));
                            }
                            else {
                                this.location.go("/search" + newUrl);
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        if (currentUrlObject.length >= 1 && !this.isSpotNearMeSearch && !this.showAllFavoritesOnly) {
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                this.location.go(this.i18nService.addCurrentLanguageToPath("/search"));
                            }
                            else {
                                this.location.go("/search");
                            }
                        }
                        else if (currentUrlObject.length === 1 && this.isSpotNearMeSearch) {
                            this.router.navigate(["/search/", 'spotsNearMe']);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.scrollToListingCard = function () {
        var _this = this;
        if (this.outerScreenWidth <= 900) {
            setTimeout(function () {
                var elementCoordinate = _this.uniqueListing.nativeElement;
                _this.parentBlock.scrollToPoint(elementCoordinate.offsetLeft, elementCoordinate.offsetTop, 500);
            }, 500);
        }
    };
    ListingSearchComponent.prototype.getMoreListings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pageSize;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchingMoreListings = true;
                        pageSize = !this.isComponentLoadedFromRegularSearch() ? 20 : 10;
                        return [4 /*yield*/, this.getListingsByPage(++this.currentPage, pageSize, this.allListingIds, true)];
                    case 1:
                        _a.sent();
                        this.searchingMoreListings = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.getStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getStates()];
                    case 1:
                        _a.states = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.getCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, cityNameFull, options;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getCitiesByState(this.searchModel.stateId)];
                    case 1:
                        _a.cities = _b.sent();
                        if (this.searchModel.cityId) {
                            cityNameFull = this.cities
                                .filter(function (city) {
                                return city.id === _this.searchModel.cityId;
                            })
                                .map(function (city) { return city.name; });
                            this.inputCity = this.i18nService.getTranslation(cityNameFull);
                            this.optionSelected = true;
                        }
                        options = {
                            keys: ['name']
                        };
                        this.fuse = new Fuse(this.cities, options);
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.getCitiesAndResetUrl = function () {
        this.searchModel.cityId = null;
        this.inputCity = '';
        this.getCities();
        this.resetUrl();
    };
    ListingSearchComponent.prototype.selectCity = function (city) {
        this.searchModel.cityId = city.id;
        this.inputCity = this.i18nService.getTranslation(city.name);
        this.optionSelected = true;
        this.cityOptions = [];
        this.resetUrl();
    };
    ListingSearchComponent.prototype.getFuzzyOptions = function () {
        if (this.optionSelected) {
            this.optionSelected = false;
            return;
        }
        if (!this.inputCity.trim().length) {
            this.cityOptions = [];
            this.searchModel.cityId = null;
            this.resetUrl();
            return;
        }
        this.cityOptions = this.fuse.search(this.inputCity);
    };
    ListingSearchComponent.prototype.removeTag = function (fieldName, index) {
        this.searchTagService.removeTagFromModels(fieldName, index, this.searchModel, this.listingSearchDto, this.checkBoxValues);
        if (fieldName.toLocaleLowerCase() === 'spotsnearme') {
            this.isSpotNearMeSearch = false;
        }
        if (fieldName.toLocaleLowerCase() === 'onlyfavorites') {
            this.showAllFavoritesOnly = false;
            this.listingSearchDto.onlyFavorites = false;
        }
        this.searchListings(true);
        if (fieldName === 'sale' || fieldName === 'lease') {
            this.avoidSearchOnListingTypeChange = true;
        }
        else if (fieldName === 'propertyType' || fieldName === 'officeType') {
            this.avoidSearchOnBuildingTypeChange = true;
        }
    };
    ListingSearchComponent.prototype.isHeaderVisible = function (event) {
        this.displayScrollToTop = event.visible ? false : true;
    };
    ListingSearchComponent.prototype.deleteSearchTagsBasedOnListingType = function () {
        this.searchTagService.deleteSearchTagsBasedOnListingType(this.listingSearchDto, this.checkBoxValues);
        this.avoidSearchOnListingTypeChange = false;
    };
    ListingSearchComponent.prototype.showNoResultMessage = function () {
        return [
            this.noListingsFoundMessage.length,
            (!this.displayMap && !this.hasGeoLocationError) || (this.displayMap && !this.hasGeoLocationError)
        ].every(function (condition) { return condition; });
    };
    ListingSearchComponent.prototype.removeSearchAttr = function (searchDTO) {
        var attrs = ['longitude', 'latitude', 'radius'];
        return this.isSpotNearMeSearch ? searchDTO : this.commonService.removeObjectAttr(searchDTO, attrs);
    };
    ListingSearchComponent.prototype.createNearbyListingMarker = function (listings) {
        var _this = this;
        return listings.reduce(function (accumulator, listing) {
            var hasValidLngLat = _this.nearbyListingSvc.hasValidCoordinate(listing, 'lat', 'lng');
            if (hasValidLngLat) {
                var infoWindow = _this.nearbyListingSvc.createNearByListingInfoWindow(listing);
                var marker_1 = _this.nearbyListingSvc.createMarker(listing, infoWindow);
                _this.commonService.changeMarkerIcon(marker_1, 'mouseover', _this.nearbyListingSvc.orangePin);
                _this.commonService.changeMarkerIcon(marker_1, 'mouseout', _this.nearbyListingSvc.bluePin);
                marker_1.addListener('click', function () {
                    var prevSelectMarker = _this.nearbyListingSvc.prevSelectedMarker(accumulator, marker_1)[0];
                    marker_1.setIcon(_this.nearbyListingSvc.orangePin);
                    marker_1.set('isSelected', true);
                    marker_1.infoWindow.open(marker_1.getMap(), marker_1);
                    _this.scrollToListingCard();
                    _this.getNearByPropertyListing(marker_1.listingIds, listing);
                    if (prevSelectMarker) {
                        _this.nearbyListingSvc.updatePrevSelectedMarker(prevSelectMarker);
                    }
                });
                marker_1.infoWindow.addListener('closeclick', function () {
                    marker_1.setIcon(_this.nearbyListingSvc.bluePin);
                    marker_1.set('isSelected', false);
                    _this.allMatchingListing = [];
                    _this.showUniquePropertyListing = false;
                });
                accumulator.push(marker_1);
            }
            return accumulator;
        }, []);
    };
    ListingSearchComponent.prototype.getUserCurrentGeoLocation = function () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.searchByGeoLocation, this.showGeoLocationError);
        }
        else {
            var errorCode = { code: { POSITION_UNAVAILABLE: 'POSITION_UNAVAILABLE' } };
            this.showGeoLocationError(errorCode);
        }
    };
    ListingSearchComponent.prototype.initSpotNearMeSearch = function () {
        var state = this.activatedRoute.snapshot.params;
        this.isSpotNearMeSearch = this.nearbyListingSvc.isSpotsNearMeSearch(state, 'from', 'spotsNearMe');
        this.displayMap = this.isSpotNearMeSearch;
    };
    ListingSearchComponent.prototype.getNearByPropertyListing = function (listingIds, selectedBuilding) {
        var _this = this;
        var searchParam = Object.assign({}, this.listingSearchDto);
        this.selectedBuilding = selectedBuilding;
        if (this.isSpotNearMeSearch) {
            var unsetAttrs = ['cityId', 'stateId', 'offeredByUserId', 'companyId', 'status'];
            searchParam.latitude = this.clientPosition.latitude;
            searchParam.longitude = this.clientPosition.longitude;
            searchParam.radius = this.clientPosition.radius;
            searchParam = this.nearbyListingSvc.unsetListingSearchDTO(this.listingSearchDto, unsetAttrs);
        }
        searchParam.listingIds = listingIds;
        this.listingService.getAllNearByPropertyListing(searchParam).then(function (result) {
            _this.allMatchingListing = result.body.listings;
            _this.showUniquePropertyListing = true;
        });
    };
    ListingSearchComponent.prototype.getAllListing = function () {
        var _this = this;
        var pageNumber = 1;
        this.listingService.searchNearByListing(this.listingSearchDto, pageNumber).then(function (result) {
            _this.totalListingsCount = parseInt(result.headers.get('x-total-count'));
            _this.noListingsFoundMessage = _this.nearbyListingSvc.getNoResultMessage(_this.totalListingsCount);
            _this.listingMarkers = _this.createNearbyListingMarker(result.body);
        });
    };
    ListingSearchComponent.prototype.nearByListingSearch = function (clientPosition) {
        var unsetAttrs = ['cityId', 'stateId', 'offeredByUserId', 'companyId', 'status'];
        this.listings = [];
        this.searching = true;
        this.listingSearchDto.latitude = clientPosition.latitude;
        this.listingSearchDto.longitude = clientPosition.longitude;
        this.listingSearchDto.radius = clientPosition.radius;
        this.listingSearchDto = this.nearbyListingSvc.unsetListingSearchDTO(this.listingSearchDto, unsetAttrs);
        this.getAllListing();
        this.regularListingSearch();
    };
    ListingSearchComponent.prototype.isComponentLoadedFromRegularSearch = function () {
        return !this.myListingPage && !this.myListingAnalytics;
    };
    ListingSearchComponent.prototype.isSmallScreenWidth = function () {
        return [
            this.outerScreenWidth < 1545 && this.allMatchingListing.length === 1,
            this.outerScreenWidth < 1545 && this.allMatchingListing.length > 1,
            this.outerScreenWidth >= 1545 && this.allMatchingListing.length === 1
        ].some(function (condition) { return condition; });
    };
    ListingSearchComponent.prototype.mapViewClass = function () {
        var isSmallScreen = this.isSmallScreenWidth();
        var mapWithCardView = isSmallScreen ? 'listing-map-view-sm' : 'listing-map-view-lg';
        return this.allMatchingListing.length ? mapWithCardView : 'map-view-fullscreen';
    };
    ListingSearchComponent.prototype.listCardViewClass = function () {
        var isSmallScreen = this.isSmallScreenWidth();
        return isSmallScreen ? 'listing-card-view-sm' : 'listing-card-view-lg';
    };
    ListingSearchComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            var chng = changes[propName];
            var cur = JSON.stringify(chng.currentValue);
            var prev = JSON.stringify(chng.previousValue);
        }
    };
    ListingSearchComponent.prototype.setInitialValuesForAdminUsers = function () {
        if (this.listingSearchDto) {
            if (this.spotAdmin && !this.listingSearchDto.spotAdmin) {
                this.listingSearchDto.companyId = null;
                this.listingSearchDto.offeredByUserId = null;
                this.listingSearchDto.spotAdmin = true;
                this.selectCompany = null;
                this.selectUser = null;
            }
            else if (this.listingAdmin && !this.listingSearchDto.listingAdmin) {
                this.listingSearchDto.listingAdmin = true;
                this.listingSearchDto.offeredByUserId = null;
                this.selectUser = null;
            }
        }
    };
    ListingSearchComponent.prototype.getAdvertisements = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingCarouselService.getAdvertisements(8).toPromise()];
                    case 1:
                        _a.advertisements = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.updateMyListingFilter = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!filter) return [3 /*break*/, 3];
                        this.statusHelperService.selectStatus = filter.selectStatus;
                        this.classHelperService.selectClass = filter.selectClass;
                        this.users = filter.agents;
                        if (filter.selectCompany) {
                            this.selectCompany = filter.selectCompany;
                            this.listingSearchDto.companyId = filter.selectCompany.id;
                            this.listingSearchDto.offeredByUserId = null;
                        }
                        if (filter.selectAgent) {
                            this.listingSearchDto.offeredByUserId = filter.selectAgent.id;
                            this.selectUser = filter.selectAgent;
                        }
                        return [4 /*yield*/, this.statusChange()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.classChange()];
                    case 2:
                        _a.sent();
                        this.searchListings(true);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListingSearchComponent.prototype.setButtonColor = function (width) {
        return this.screenWidth >= width ? 'light' : 'secondary';
    };
    ListingSearchComponent.prototype.setMetaTags = function () {
        var _this = this;
        if (this.isComponentLoadedFromRegularSearch()) {
            if (this.currentTags) {
                if (this.currentTags instanceof Promise) {
                    this.currentTags.then(function (currentTags) {
                        var title = _this.searchTagService.getMetatagTitleBasedOnCurrentTags();
                        var description = _this.i18nService.getMetatagTranslation('metatags.searchPage.description');
                        var tags = _this.metaService.getMetaTagsFrom(title, description);
                        _this.metaService.setDynamicTags(tags, title);
                    });
                }
                else {
                    var title = this.searchTagService.getMetatagTitleBasedOnCurrentTags();
                    var description = this.i18nService.getMetatagTranslation('metatags.searchPage.description');
                    var tags = this.metaService.getMetaTagsFrom(title, description);
                    this.metaService.setDynamicTags(tags, title);
                }
            }
        }
    };
    ListingSearchComponent.prototype.openRenewExtendListingsModal = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.popOverCtrlHelper.openRenewExtendListingsModal(ev, this.allListingIds, function (popoverData) {
                    if (popoverData && popoverData.updated) {
                        _this.searchMyListings(_this.listingSearchDto);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ListingSearchComponent.prototype.showTotalActiveListingCounts = function () {
        return (this.listingSearchDto &&
            this.listingSearchDto.listingStatuses &&
            this.myListingPage &&
            this.listingSearchDto.listingStatuses.length == 1 &&
            this.listingSearchDto.listingStatuses.includes(4));
    };
    ListingSearchComponent.prototype.showListingTypeBtn = function () {
        return !(this.listingSearchDto &&
            this.listingSearchDto.buildingTypes &&
            this.listingSearchDto.buildingTypes[0] === ListingOfferType.Coworking);
    };
    return ListingSearchComponent;
}());
export { ListingSearchComponent };
